import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import RoutePathNames from 'routes/routePathNames'
import { ROLES } from 'utils/utils'
import { useAuthContext } from 'context/auth-context'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import RoleBox from 'components/common/RoleBox'
import CompanySvg from 'static/svg/welcome-page-icon-1.svg'
import ConsultantSvg from 'static/svg/welcome-page-icon-2.svg'
import ManagerSvg from 'static/svg/welcome-page-icon-3.svg'

export default function Welcome() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const auth = useAuthContext()
  const { role, setRole } = auth

  const isCompanySelected = role === ROLES.COMPANY
  const isConsultantSelected = role === ROLES.CONSULTANT
  const isManagerSelected = role === ROLES.MANAGER

  const onCreateAccount = () => {
    if (isCompanySelected) {
      navigate(RoutePathNames.auth.registrationCompany, { replace: true, state: { pathname: previousPath } })
    } else if (isConsultantSelected) {
      navigate(RoutePathNames.auth.registrationConsultant, { replace: true, state: { pathname: previousPath } })
    } else if (isManagerSelected) {
      navigate(RoutePathNames.auth.registrationManager, { replace: true, state: { pathname: previousPath } })
    }
  }

  const { t } = useTranslation()

  return (
    <AuthBoxWrapper>
      <Stack spacing={4}>
        <div>
          <Typography variant="h3" align="center">
            {t('WelcomePage.Title')}
          </Typography>
          <Typography component="div" variant="body" color="primary.60" align="center" mt={2}>
            {t('WelcomePage.Description')}
          </Typography>
        </div>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2.5}
        >
          <RoleBox
            id="company"
            avatar={{
              src: CompanySvg,
              alt: 'Company',
              sx: { width: 70, height: 60 },
            }}
            text={t('WelcomePage.CompanyOption')}
            isSelected={isCompanySelected}
            onClick={() => setRole(ROLES.COMPANY)}
          />
          <RoleBox
            id="consultant"
            avatar={{
              src: ConsultantSvg,
              alt: 'Consultant',
              sx: { width: 65, height: 60 },
            }}
            text={t('WelcomePage.ConsultantOption')}
            isSelected={isConsultantSelected}
            onClick={() => setRole(ROLES.CONSULTANT)}
          />
          <RoleBox
            id="manager"
            avatar={{
              src: ManagerSvg,
              alt: 'Manager',
              sx: { width: 60, height: 60 },
            }}
            text={t('WelcomePage.ManagerOption')}
            isSelected={isManagerSelected}
            onClick={() => setRole(ROLES.MANAGER)}
          />
        </Stack>
        <Button
          id="create-account"
          sx={{ width: { xs: 'none', sm: '50%' }, alignSelf: 'center' }}
          variant="contained"
          color="secondary"
          size="large"
          disabled={!(isCompanySelected || isConsultantSelected || isManagerSelected)}
          onClick={onCreateAccount}
        >
          {t('Registration.CreateAccount')}
        </Button>
        <Typography component="div" variant="body" align="center">
          {t('Registration.AlreadyHaveAccount')}{' '}
          <Link
            to={RoutePathNames.auth.login}
            state={{ pathname: previousPath }}
          >
            <b id="login">{t('Registration.LogIn')}</b>
          </Link>
        </Typography>
      </Stack>
    </AuthBoxWrapper>
  )
}

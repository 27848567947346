import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Typography, Stack, Button } from '@mui/material'
import { ROLES } from 'utils/utils'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import * as schemas from 'validation/schemas'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import ScrollToError from 'components/common/ScrollToError'
import StyledPasswordStrengthBar from 'components/common/StyledPasswordStrengthBar'
import AuthBoxWrapper from 'components/common/AuthBoxWrapper'
import InputField from 'components/ui/FormikFields/InputField'
import PasswordField from 'components/ui/FormikFields/PasswordField'
import CheckboxField from 'components/ui/FormikFields/CheckboxField'

const validationSchema = Yup.object({
  org_number: schemas.notRequiredOrgNumber(),
  username: Yup.string().required('Registration.EnterUsername').min(5, 'Registration.ShortName'),
  email: Yup.string().email('Registration.EnterValidEmail').required('Registration.EnterEmail'),
  password: schemas.requiredPassword({ requiredMsg: 'Registration.EnterPass' }),
  confirmPassword: Yup.string()
    .required('Registration.EnterConfirmPass')
    .oneOf([Yup.ref('password'), null], 'Registration.PassMustMatch'),
  agreement: Yup.boolean()
    .required('Registration.Agreement')
    .oneOf([true], 'Registration.Agreement'),
})

const initialValues = {
  org_number: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  agreement: false,
}

export default function RegistrationConsultant() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const { setRole, registrationConsultant } = useAuthContext()

  useEffect(() => {
    setRole(ROLES.CONSULTANT)
    // eslint-disable-next-line
  }, [])

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const onSubmit = async (values: typeof initialValues) => {
    const { username, org_number, email, password } = values

    try {
      await registrationConsultant(
        {
          name: username,
          email,
          password,
          org_number,
        },
        () => {
          navigate(RoutePathNames.consultant.uploadCvs, { replace: true, state: { pathname: previousPath } })
        }
      )
    } catch (error: any) { }
  }

  const { t } = useTranslation()

  return (
    <Formik
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, dirty, isSubmitting, values } = formikProps
        return (
          <AuthBoxWrapper>
            <ScrollToError />
            <Form onSubmit={handleSubmit}>
              <Typography variant="h3" align="center">
                {t('Registration.CreateAccount')}
              </Typography>
              <Stack direction="column" spacing={1} mt={4}>
                <InputField id="name" name="username" label={t('Common.FullName')} fullWidth />
                <InputField id="org-number" name="org_number" label={t('Common.OrgNumber')} fullWidth />
                <InputField id="email" name="email" label={t('Common.Email')} fullWidth />
                <div>
                  <PasswordField name="password" label={t('Common.Password')} fullWidth />
                  {values.password && <StyledPasswordStrengthBar password={values.password} />}
                </div>
                <PasswordField
                  name="confirmPassword"
                  label={t('Common.ConfirmPassword')}
                  fullWidth
                />
              </Stack>
              <Stack direction="row">
                <CheckboxField id="agreement" name="agreement" color="secondary" />
                <label htmlFor="agreement" style={{ marginTop: 10 }}>
                  {t('Registration.AgreeToTerms')}{' '}
                  <b>
                    <Link to={RoutePathNames.termsConditions} target="_blank">
                      {t('Registration.TermsAndConditions')}
                    </Link>
                  </b>
                </label>
              </Stack>
              <Button
                id="create-account"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                sx={{ mt: 1.5, mb: 4 }}
                disabled={!dirty || isSubmitting}
                onClick={manualSubmitForm(formikProps)}
              >
                {t('Registration.CreateAccount')}
              </Button>
              <Typography component="div" variant="body" align="center">
                {t('Registration.AlreadyHaveAccount')}{' '}
                <Link to={RoutePathNames.auth.login}>
                  <b id="login">{t('Registration.LogIn')}</b>
                </Link>
              </Typography>
            </Form>
          </AuthBoxWrapper>
        )
      }}
    </Formik>
  )
}

import React, { useState, useEffect } from 'react'
import { api } from '../../api/api'
import { Link, useSearchParams } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, PaginationItem, Box, Typography, Select, MenuItem, SelectChangeEvent, FormControl, InputLabel, Stack } from '@mui/material';
import { StyledPagination } from 'components/common/StyledPagination';
import { getPaginationCount, getPaginationOffset } from 'utils/utils';
import usePrevious from 'hooks/usePrevious';

type PaginationParams = {
  limit: number
  offset: number
  total: number
}

type Users = {
  user_id: string
  name: string
  email: string
  manager_email?: string
  account_type: string
  org_number: number
  created_date: string
}[]

export default function AdminLoadUsers() {
  const [searchParams, setSearchParams] = useSearchParams()
  const parsedPage = Number.parseInt(searchParams.get('page') || '1', 10);
  const [page, setPage] = useState(isNaN(parsedPage) ? 1 : parsedPage);

  const [paginationParams, setPaginationParams] = useState<PaginationParams>();

  const prevPage = usePrevious(page)
  const [pageLimit, setPageLimit] = useState<number>(50);
  const offset = getPaginationOffset(page, pageLimit)

  const total = paginationParams?.total || 10
  const paginationCount = getPaginationCount(total, pageLimit)

  const [data, setData] = useState<Users>([])

  const fetchData = async () => {
    try {
      const response = await api.get(`admin/users?offset=${offset}&limit=${pageLimit}`)
      const users: Users = response.data.consultants
      setPaginationParams(response?.data?.paginationParams)
      const usersWithParsedDates = users.map((user: Users[0]) => ({
        ...user,
        created_date: new Date(user.created_date).toLocaleDateString()
      }))
      setData(usersWithParsedDates)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    setPageLimit(event.target.value as number)
    onPaginationChange(1)
  }

  const onPaginationChange = (newPage: number) => {
    setPage(newPage)
    setSearchParams(() => {
      const params = new URLSearchParams(searchParams)
      params.set('page', newPage.toString())
      return params
    })
  }

  // Logic for the initial call and the pagination
  useEffect(() => {
    fetchData()
  }, [page, prevPage, pageLimit])

  return (
    <>
      <Stack direction="row" my="16px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Users: {total}</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Page Size</InputLabel>
            <Select
              id="page-limit-select"
              label="Page Size"
              value={pageLimit}
              onChange={handleLimitChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={10000}>10 000</MenuItem>
            </Select>
          </FormControl>
          <StyledPagination
            id="upper-pagination"
            page={page}
            count={paginationCount}
            shape="rounded"
            color="secondary"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                component="button"
                onClick={() => onPaginationChange(item.page || 1)}
              />
            )}
          />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Manager Email</TableCell>
              <TableCell>Account Type</TableCell>
              <TableCell>Org Number</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((item) => (
              <TableRow
                key={item.user_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link id={`user-${item.user_id}-profile`} to={`/consultant/public-profile/${item.user_id}`}>
                    {item.name}
                  </Link>
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.manager_email}</TableCell>
                <TableCell>{item.account_type}</TableCell>
                <TableCell>{item.org_number}</TableCell>
                <TableCell>{item.created_date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack mt="16px" direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Page Size</InputLabel>
          <Select
            id="page-limit-select"
            label="Page Size"
            value={pageLimit}
            onChange={handleLimitChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={10000}>10 000</MenuItem>
          </Select>
        </FormControl>
        <StyledPagination
          id="lower-pagination"
          page={page}
          count={paginationCount}
          shape="rounded"
          color="secondary"
          renderItem={(item) => (
            <PaginationItem
              {...item}
              component="button"
              onClick={() => onPaginationChange(item.page || 1)}
            />
          )}
        />
      </Stack>
    </>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { format, formatDistanceToNow } from 'date-fns'
import {
  Container,
  Avatar,
  Box,
  Skeleton,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import { getWorkloadStatus, getRemoteDaysPerWeekStatus, checkIsSkillPrimary } from 'utils/utils'
import { useCompanyByUserIdQuery } from 'api/company/queries'
import { CreateAssignmentFormValues } from 'layouts/AssignmentLayout'
import Chip from 'components/ui/Chip'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import rolelistIcon from 'static/svg/rolelist.svg'

const skeletonSkills = Array.from(new Array(10), (_, i) => ({ name: `${i}` }))

export default function PreviewAssignment() {
  const { t } = useTranslation()

  const { data: companyData } = useCompanyByUserIdQuery()
  const {
    fk_user_id,
    profile_photo,
    cover_picture,
    company_name = '',
    company_description = '',
  } = companyData || {}

  const { values, isSubmitting } = useFormikContext<CreateAssignmentFormValues>()
  const {
    jobTitle = '',
    workLoad = 0,
    remoteDaysPerWeek = 0,
    summary = '',
    roleDescription = [],
    skills = [],
    totalYearsExperience = 0,
    createdAt,
    availableFrom = new Date().getTime(),
    applicationDeadline,
    hourlyRate: { price = 0, currency = 'SEK' } = {},
    duration = 0,
    location,
  } = values
  const city = location?.city || ''
  const available = format(availableFrom ? new Date(availableFrom) : new Date(), 'yyyy-MM-dd')
  const deadline = format(
    applicationDeadline ? new Date(applicationDeadline) : new Date(),
    'yyyy-MM-dd'
  )

  const roleDescItems = roleDescription.map(({ description }, idx) => (
    <ListItem key={idx} dense={false} sx={{ p: 0, mb: '10px', alignItems: 'normal' }}>
      <ListItemIcon sx={{ minWidth: '24px' }}>
        <Avatar
          alt=""
          src={rolelistIcon}
          sx={{ width: 16, height: 16, mt: '2px', borderRadius: '0' }}
        />
      </ListItemIcon>
      <Typography variant="body" lineHeight={1.4} width="100%">
        {description}
      </Typography>
    </ListItem>
  ))

  return (
    <>
      <Box
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${cover_picture})`,
          height: '218px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'secondary.108',
        }}
      />
      <Container
        maxWidth="2lg"
        sx={{
          mt: '-75px',
          p: { xs: '0px 16px 80px', lg: '0px 80px 160px' },
        }}
      >
        <Box>
          <Box mb={4}>
            <Avatar
              alt="logo"
              src={profile_photo ?? ''}
              sx={{ width: 150, height: 150, borderRadius: 4 }}
            />
          </Box>
          <Box
            component={Link}
            to={`${RoutePathNames.company.profile}/${fk_user_id}`}
            sx={{ display: 'inline-block', mb: 2 }}
          >
            <Typography variant="h4" fontWeight="fontWeightLight">
              {company_name ? company_name : <Skeleton width={200} />}
            </Typography>
          </Box>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 7.5 }}
            alignItems={{ xs: 'left', sm: 'center' }}
            justifyContent="space-between"
            pb={{ xs: 2, sm: 0 }}
            mb="20px"
          >
            <Typography variant="h3">{jobTitle ? jobTitle : <Skeleton width={300} />}</Typography>
            <Stack direction="row" spacing={2}>
              <Link to={RoutePathNames.company.assignment.create}>
                <Button id="close" variant="outlined" size="large">
                  {t('general.closePreview')}
                </Button>
              </Link>
              <Button
                id="create"
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="secondary"
                size="large"
              >
                {t('general.create')}
              </Button>
            </Stack>
          </Stack>

          <Stack direction="row" mb={7.5} gap={1.5} flexWrap="wrap">
            <ChipWithIcon label={values?.location?.city} img="location" imgType="svg" />
            <ChipWithIcon
              label={workLoad ? t(getWorkloadStatus(workLoad as number)) : ''}
              img="clock"
              imgType="svg"
            />
            <ChipWithIcon
              label={t(getRemoteDaysPerWeekStatus(remoteDaysPerWeek as number))}
              img="earth"
              imgType="svg"
            />
          </Stack>

          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={{ md: 0, xs: 4 }}
            justifyContent="space-between"
          >
            <Box sx={{ pr: { md: '32px', xs: 0 } }} flexGrow={1} maxWidth="752px">
              <Typography variant="h4" mb="16px">
                {t('Common.AboutThisAssignment')}
              </Typography>
              <Typography variant="body" sx={{ display: 'block' }} mb="32px">
                {summary ? summary : null}
              </Typography>

              <Typography variant="h4" mb="16px">
                {t('Common.RoleDescription')}
              </Typography>
              <List>{roleDescItems}</List>
            </Box>

            <Stack direction="column" spacing={4}>
              <Box
                sx={(theme) => ({
                  width: { md: 436, xs: '100%' },
                  bgcolor: theme.palette.common.white,
                  borderRadius: theme.shape.rounded,
                  p: 4,
                })}
              >
                <Stack direction="row" mb={1.5} gap={1} alignItems="baseline">
                  <Typography variant="h5">{t('Common.Skills')}</Typography>
                  <Typography variant="body-sm" color="primary.60">
                    {t('Common.SelectedPrimary')}
                  </Typography>
                </Stack>

                <Stack direction="row" mb={3} flexWrap="wrap" gap={1}>
                  {skills.length > 0
                    ? skills.map(({ name, isPrimary }: any) => (
                      <Chip
                        key={name}
                        variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
                        label={name}
                      />
                    ))
                    : skeletonSkills.map(({ name }) => (
                      <Skeleton key={name} width={80}>
                        <Chip label={name} />
                      </Skeleton>
                    ))}
                </Stack>

                <Typography variant="h5">{t('Common.Experience')}</Typography>
                <Typography variant="body" sx={{ display: 'block' }} mb={4} color="primary.60">
                  {totalYearsExperience ? (
                    t('Common.YearsExp', { years: totalYearsExperience })
                  ) : (
                    <Skeleton width={100} />
                  )}
                </Typography>

                <Stack direction="row" mb={2} alignItems="center">
                  <Avatar
                    alt="small-logo"
                    src={profile_photo ?? ''}
                    sx={{ width: 48, height: 48, borderRadius: '7px', mr: 1.5 }}
                  />
                  <Stack direction="row" color="primary.40" flexWrap="wrap">
                    <Typography variant="body" color="primary" fontWeight="fontWeightBold" mr={1}>
                      {company_name ? company_name : <Skeleton component="span" width={130} />}
                    </Typography>
                    <Typography fontWeight={300}>
                      {createdAt ? (
                        t('Common.PublishedDaysAgo', {
                          date: formatDistanceToNow(createdAt, { addSuffix: true }),
                        })
                      ) : (
                        <Skeleton width={130} />
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Typography variant="body" color="primary.60">
                  {company_description ? company_description : null}
                </Typography>
              </Box>

              <Box
                sx={(theme) => ({
                  width: { md: 436, xs: '100%' },
                  bgcolor: theme.palette.common.white,
                  borderRadius: theme.shape.rounded,
                  p: 4,
                })}
              >
                <Stack direction="column" spacing={2}>
                  <Typography variant="body">
                    <Trans i18nKey="general.location" city={city}>
                      <strong></strong>: {{ city }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.assignmentStart" available={available}>
                      <strong></strong>: {{ available }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.assignmentLength" count={duration as number}>
                      <strong></strong>: {{ duration }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.latestDateToAnswer" deadline={deadline}>
                      <strong></strong>: {{ deadline }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.hourlyRate" count={price as number} currency={currency}>
                      <strong></strong>: {{ price }} {{ currency }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.workLoad" count={workLoad as number}>
                      <strong></strong>: {{ workLoad }}
                    </Trans>
                  </Typography>
                  <Typography variant="body">
                    <Trans i18nKey="general.workFromHome" count={remoteDaysPerWeek as number}>
                      <strong></strong>: {{ remoteDaysPerWeek }}
                    </Trans>
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  )
}

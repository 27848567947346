import React, { useMemo, useState } from 'react'
import { Avatar, Box, Button, Dialog, DialogContent, Divider, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAuthContext } from 'context/auth-context';
import { ApplicantStatus } from './ApplicationHandler';
import { useInterviewResponseMutation } from 'api/assignments/mutations';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import { enqueueSnackbar } from 'notistack';

type InterviewInvitationProps = {
  applicantStatus: ApplicantStatus
  companyName: string
  assignmentId: number
}

export default function InterviewInvitation({
  applicantStatus,
  companyName,
  assignmentId 
}: InterviewInvitationProps) {
  const { t } = useTranslation();

  const { isUserRoleManager, isUserRoleConsultant } = useAuthContext();
  const { mutateAsync, isLoading } = useInterviewResponseMutation(assignmentId);

  const [selectedConsultant, setSelectedConsultant] = useState(applicantStatus.interview[0].user_id);

  const [interviewStatus, setInterviewStatus] = useState(applicantStatus.interview);
  const [accept, setAccept] = useState(true);

  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isInterviewAccepted = useMemo(() => {
    const selectedApplicant = interviewStatus.find(applicant => applicant.user_id === selectedConsultant);
    return selectedApplicant?.accepted_interview;
  }, [selectedConsultant, interviewStatus]);

  const onSubmit = () => {
    mutateAsync([{
      accept,
      consultant_id: selectedConsultant
    }], {
      onSuccess: () => {
        enqueueSnackbar('Email sent!', { variant: 'success' });
        
        setInterviewStatus(prevStatus =>
          prevStatus.map(applicant => {
            if (applicant.user_id === selectedConsultant) {
              applicant.accepted_interview = accept
            }
            return applicant
          })
        );
      },
      onSettled: () => closeModal()
    })
  }

  const onAccept = () => {
    setAccept(true);
    openModal();
  }

  const onDecline = () => {
    setAccept(false);
    openModal();
  }

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={closeModal}
        PaperProps={{ sx: { maxWidth: { xs: 440, sm: 520 }, width: '100%', m: 2 } }}
      >
        <DialogContent sx={{ px: { xs: 1, sm: 6 }, py: 4 }}>
          <IconButton
            onClick={closeModal}
            sx={{
              p: 0,
              top: 16,
              right: 16,
              position: 'absolute',
            }}
          >
            <CloseIcon id="close" sx={{ fontSize: '18px' }} />
          </IconButton>
          <Typography variant="h3" align="center" mb={2}>
            {t(accept ? 'jobListings.acceptInterview' : 'jobListings.declineInterview')}
          </Typography>
          <Divider sx={{ mb: 4 }} />
            <Typography
              variant="body"
              align="center"
              color="text.secondary"
              display="inline-block"
              mb={4}
            >
              {t(accept ? 'jobListings.areYouSureAccept' : 'jobListings.areYouSureDecline', { companyName })}
            </Typography>
            <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
              <Button
                id="confirm"
                disabled={isLoading}
                onClick={onSubmit}
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
              >
                {t(isLoading ? 'general.confirming' : 'general.confirm')}
              </Button>
            </Box>
        </DialogContent>
      </Dialog>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={1}
        alignItems={{ xs: 'stretch', md: 'center' }}
        sx={{ width: '100%' }}
      >
        {isUserRoleConsultant &&
          <Typography variant='h4'>
            {t('jobListings.invitedYouForInterview', { companyName })}
          </Typography>
        }
        {isUserRoleManager &&
          <>
            <Typography variant='h4'>
              {t('jobListings.invitedConsultantForInterview', { companyName })}
            </Typography>
            <Select
              id="interview-invitations"
              defaultValue={applicantStatus.interview[0].user_id}
              onChange={event => setSelectedConsultant(event.target.value)}
              sx={{
                width: { xs: 'auto', md: '260px' },
                '& .MuiSelect-select': {
                  paddingRight: 4,
                  paddingLeft: 1,
                  paddingTop: 1,
                  paddingBottom: 1,
               }
              }}
            >
              {applicantStatus.interview.map(applicant => (
                <MenuItem
                  value={applicant.user_id}
                  key={applicant.user_id}
                  sx={
                    applicant.accepted_interview !== null ? {
                      color: 'text.disabled',
                      opacity: 0.5
                    } : {}
                  }
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar src={applicant.profile_photo} />
                    <Typography>
                      {applicant.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </>
        }
        <Stack
          direction="row"
          justifyContent="stretch"
          spacing={1}
        >
          {(isInterviewAccepted === null || isInterviewAccepted) &&
            <Button
              onClick={onAccept}
              size='large'
              variant='contained'
              color='secondary'
              sx={{ flexGrow: 1 }}
              disabled={isInterviewAccepted !== null}
              startIcon={<CheckIcon/>}
            >
              {t(isInterviewAccepted !== null ? 'jobListings.accepted' : 'jobListings.accept')}
            </Button>
          }
          {(isInterviewAccepted === null || !isInterviewAccepted) &&
            <Button
              onClick={onDecline}
              size='large'
              variant='outlined'
              color='secondary'
              sx={{ flexGrow: 1 }}
              disabled={isInterviewAccepted !== null}
              startIcon={<CloseIcon/>}
            >
              {t(isInterviewAccepted !== null ? 'jobListings.declined' : 'jobListings.decline')}
            </Button>
          }
        </Stack>
      </Stack>
    </>
  );
}
import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Box,
  Container,
  Stack,
  Typography,
  Skeleton,
  CircularProgress,
  PaginationItem,
  // Button,
} from '@mui/material'
import { getPaginationCount, getPaginationOffset } from 'utils/utils'
import { useCompanyByUserIdQuery, useCompanyAssignmentsByCompanyIdQuery } from 'api/company/queries'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import AssignmentCard from 'components/AssignmentCard'
import { StyledPagination } from 'components/common/StyledPagination'
// import mailIcon from 'static/svg/mail.svg';
import CoverImage from 'components/common/Cover'

export default function CompanyProfile() {
  const params = useParams<{ userId: string }>()
  const companyByUserId = useCompanyByUserIdQuery(params?.userId)
  const { data: companyData } = companyByUserId

  const [searchParams] = useSearchParams()
  const parsedPage = Number.parseInt(searchParams.get('page') || '1', 10);
  const page = isNaN(parsedPage) ? 1 : parsedPage;
  const offset = getPaginationOffset(page)

  const { data: companyAssignmentsData, isLoading: companyAssignmentsIsLoading } =
    useCompanyAssignmentsByCompanyIdQuery(companyData?.company_id, offset)
  const { assignments = [], total = 0, otherTotal = 0 } = companyAssignmentsData || {}

  const paginationCount = getPaginationCount(total || otherTotal || 0)

  const { t } = useTranslation()

  const {
    profile_photo,
    cover_picture,
    company_name = '',
    company_description = '',
    city = '',
    country = '',
  } = companyData || {}

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ pb: 10 }}
    >
      <CoverImage src={cover_picture} />
      <Container>
        <Box mb={4}>
          <Avatar
            alt="logo"
            src={profile_photo ?? ''}
            sx={{
              width: { xs: 100, sm: 150 },
              height: { xs: 100, sm: 150 },
              borderRadius: 4,
              mt: { xs: '-50px', sm: '-75px' }
            }}
          />
        </Box>

        <Typography variant="h2" mb={2}>
          {company_name ? company_name : <Skeleton width={200} />}
        </Typography>

        <Box my={4}>
          <ChipWithIcon label={`${city}, ${country}`} img="location" imgType="svg" />
        </Box>

        {/* <Box my={4}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<Avatar alt="" src={mailIcon} sx={{ width: 19, height: 16, borderRadius: '0', mr: '1px' }} />}
          >
            {t('Common.AskQuestion')}
          </Button>
        </Box> */}

        <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ xs: 4, md: 0 }} mb="60px">
          <Typography
            variant="body"
            sx={{ display: 'inline-block' }}
            color="primary.60"
            maxWidth={720}
          >
            {company_description ? (
              company_description
            ) : (
              <>
                <Skeleton sx={{ mb: 1 }} width={720} />
                <Skeleton sx={{ mb: 1 }} />
                <Skeleton sx={{ mb: 1 }} />
              </>
            )}
          </Typography>
        </Stack>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ mb: 4 }}
        >
          <Typography variant="h3">
            {t('companyProfileByUserId.assignmentsRightNowFrom', { companyName: company_name })}
          </Typography>
          <StyledPagination
            page={page}
            count={paginationCount}
            shape="rounded"
            color="secondary"
            renderItem={(item) => (
              <PaginationItem component={Link} to={`${`?page=${item.page}`}`} {...item} />
            )}
            sx={{ flexShrink: 0 }}
          />
        </Stack>
        {companyByUserId.isLoading || (companyAssignmentsIsLoading && !companyAssignmentsData) ? (
          <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Stack
            gap={2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {assignments.map((item) => (
              <AssignmentCard key={item.assignment_id} card={item} />
            ))}
          </Stack>
        )}
      </Container>
    </Container>
  )
}

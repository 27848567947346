import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  Stack,
  Paper,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import { Assignments } from 'api/assignments/queries'
import { BiTimeFive } from 'react-icons/bi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { format, formatDistanceToNow } from 'date-fns'
import ApplicantList from 'components/common/ApplicantList'
import { TabType } from 'api/types'
import MoreVertIcon from '@mui/icons-material/MoreVert';

type AssignmentCardLandingProps = {
  assignment: Assignments[0]
  tab: TabType
}

export default function AssignmentCardLanding({ assignment, tab }: AssignmentCardLandingProps) {
  const [isHovered, setIsHovered] = useState(false);

  const { assignment_id, body, applicants = [],  } = assignment
  const { jobTitle, duration, applicationDeadline, availableFrom, createdAt, location, workLoad } =
    body
  const { t } = useTranslation()

  const notDeletedApplicants = useMemo(
    () => applicants.filter((applicant) => !applicant.is_delete),
    [applicants]
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ButtonBase
      sx={(theme) => ({
        maxWidth: { xs: '100%', sm: 276 },
        width: '100%',
        borderRadius: theme.shape.rounded
      })}
    >
      <Paper
        elevation={isHovered ? 8 : 2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        component={Link}
        to={`${RoutePathNames.consultant.jobListings}/${assignment_id}`}
        sx={(theme) => ({
          padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
          borderRadius: theme.shape.rounded,
          bgcolor: 'common.white',
          width: '100%'
        })}
      >
        <Stack spacing={1.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: '1.5rem' }}
          >
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100%',
              }}
              variant="h5"
            >
              {jobTitle}
            </Typography>
            <div
              onMouseDown={event => event.stopPropagation()} // Prevent children from triggering ButtonBase ripple
              onClick={event => event.preventDefault()} // Prevent children from triggering Link navigation
            >
              <IconButton
                aria-label="more"
                id="long-button"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      width: '20ch',
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <MenuItem
                  component={Link}
                  to={`${RoutePathNames.company.assignment.edit.base}/${assignment_id}`}
                  id={`assignment-${assignment_id}-edit`}
                  disabled={tab === 'completed'}
                >
                  {t('CompanyLanding.Edit')}
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={`${RoutePathNames.company.assignment.copy.base}/${assignment_id}`}
                  id={`assignment-${assignment_id}-create-copy`}
                >
                  {t('CompanyLanding.CreateCopy')}
                </MenuItem>
              </Menu>
            </div>
          </Stack>
          <Box display="flex" mb="11px" alignItems="center" gap={1} mt={1.5}>
            <Box display="flex" alignItems="center" gap={0.5} color="secondary.main">
              <HiOutlineLocationMarker className="text-violet-blue" size={16} />
              <Typography variant="body-sm" color="primary.60">
                {location.city && location.city}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1} color="secondary.main">
              <BiTimeFive className="text-violet-blue" size={16} />
              <Typography variant="body-sm" color="primary.60">
                {workLoad && workLoad === 100 ? t('landing.fullTime') : t('landing.partTime')}
              </Typography>
            </Box>
          </Box>

          <Box mt="5px" mb="11px" display="flex" flexWrap="wrap" alignItems="center" gap={1}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="body-sm" fontWeight={400} color="primary.80">
                {t('landing.start')}
              </Typography>
              <Typography variant="body-sm" color="primary.60">
                {availableFrom && format(new Date(availableFrom), 'yyyy-MM-dd')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="body-sm" fontWeight={400} color="primary.80">
                {t('landing.duration')}
              </Typography>
              <Typography variant="body-sm" color="primary.60">
                {duration && t('CompanyLanding.MonthsCount', { number: duration })}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" mb="11px" alignItems="center" gap={0.5}>
            <Typography variant="body-sm" fontWeight={400} color="primary.80">
              {t('landing.published')}
            </Typography>
            <Typography variant="body-sm" color="primary.60">
              {createdAt && formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
            </Typography>
          </Box>

          <Box display="flex" mb="11px" alignItems="center" gap={0.5}>
            <Typography variant="body-sm" fontWeight={400} color="primary.80">
              {t('landing.applicationDeadline')}
            </Typography>
            <Typography variant="body-sm" color="primary.60">
              {applicationDeadline && format(new Date(applicationDeadline), 'yyyy-MM-dd')}
            </Typography>
          </Box>
          <Stack
            direction="column"
            alignItems="start"
            spacing={1.5}
          >
            {notDeletedApplicants.length > 0 ? (
              <ApplicantList
                applicants={notDeletedApplicants}
                max={{ xs: 5, sm: 7 }}
                size="small"
                title={t('landing.applicants', { count: notDeletedApplicants.length })}
              />
            ) : (
              <Typography variant="body-sm" fontWeight={500} flexGrow={1} height={28} alignContent="center">
                {t('landing.noApplicants')}
              </Typography>
            )}
            <Button
              component={Link}
              to={`${RoutePathNames.consultant.jobListings}/${assignment_id}?tab=applicants`}
              id={`assignment-${assignment_id}-applicants`}
              variant="contained"
              color="secondary"
              sx={{ fontSize: '12px' }}
              onMouseDown={event => event.stopPropagation()}
              disabled={notDeletedApplicants.length === 0}
            >
              {t('CompanyLanding.ManageApplicants')}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </ButtonBase>
  )
}

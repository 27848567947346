import { setLastReload, shouldReloadPage } from 'utils/utils';
import { useEffect } from 'react';

export const LOCAL_STORAGE_KEY_RELOAD_AFTER = 'reloadAfterTimestamp';
export const SESSION_STORAGE_KEY_LAST_RELOAD = 'lastReloadTimestamp';

/**
 * This custom hook is a wrapper for useEffect(). This hook automatically reloads
 * each tab the first time it gains focus after notifyReload() has been called.
 */
export default function useReloadEffect() {
  useEffect(() => {
    const handleFocus = () => {
      if (shouldReloadPage()) {
        setLastReload(new Date());
        window.location.reload();
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);
}

import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import Sticky from 'react-stickynode'
import { Box, Button, Stack, Tooltip, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { getPublishDateOptions } from 'utils/utils'
import { useAuthContext } from 'context/auth-context'
import { Skills } from 'api/cvs/queries'
import { useSkillOptionsQuery } from 'api/skills/queries'
import { useRoleOptionsQuery } from 'api/roles/queries'
import { useLanguageOptionsQuery, LanguageOptions } from 'api/languages/queries'
import useQueryParam from 'hooks/useQueryParam'
import AutocompleteField from '../ui/FormikFields/AutocompleteField/AutocompleteField'
import SliderField from '../ui/FormikFields/SliderField'
import InputField from '../ui/FormikFields/InputField'
import SelectField from '../ui/FormikFields/SelectField'
import GoogleAutocompleteField from '../ui/FormikFields/GoogleAutocompleteField'
import ScrollToError from '../common/ScrollToError'
import CheckboxField from 'components/ui/FormikFields/CheckboxField'
import * as DEFAULTS from '../../api/constants/filterDefaults'
import SearchDatePickerField from 'components/ui/FormikFields/DatePickerField/SearchDatePickerField'
import SearchNumberField from 'components/ui/FormikFields/NumberField/SearchNumberField'
import DateBuilder from 'utils/classes/DateBuilder'

export type FormValues = {
  skills: Skills;
  roles: { name: string }[];
  companyName: string | null;
  location: {
    city: string;
    formatted?: string;
    lat: number | null;
    lng: number | null;
  };
  availableFrom: Date | null;
  distanceToWork: number[];
  // Always a number except when Formik defaults to an empty string
  hourlyRate: number | "";
  workLoad: number[];
  remoteDaysPerWeek: number[];
  languages: LanguageOptions;
  // Always a number except when Formik defaults to an empty string
  publishDate: number | "";
  onlyManagerConsultants: boolean;
  consultantId: number | null;
  assignmentId: number | null;
};

export type Options = {
  label: string
  value: number
}[]

type FilterChangeSource = 'browser navigation or pagination event' | ''

const DEFAULT_PAGE = 1
const publishDateOptions = getPublishDateOptions()

const isDefault = (val1: FormValues, searchType: SearchType): boolean => {
  const val2 = getDefaultValues(searchType);
  return areEqualExcludingUndefined(val1, val2);
};

const areEqualExcludingUndefined = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  // Consider undefined, empty string, and null as equal
  if ((obj1 === undefined || obj1 === '' || obj1 === null) &&
    (obj2 === undefined || obj2 === '' || obj2 === null)) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const allKeys = new Set([...keys1, ...keys2]);

  for (const key of allKeys) {
    if (!areEqualExcludingUndefined(obj1[key], obj2[key])) return false;
  }

  return true;
};

export const getDefaultValues = (searchType: SearchType): FormValues => {
  return {
    skills: [],
    roles: [],
    location: {
      city: DEFAULTS.LOCATION_CITY,
      lat: DEFAULTS.LOCATION_LAT,
      lng: DEFAULTS.LOCATION_LNG
    },
    distanceToWork: [DEFAULTS.DISTANCE_TO_WORK],
    availableFrom: DEFAULTS.AVAILABLE_FROM,
    hourlyRate: DEFAULTS.HOURLY_RATE,
    workLoad: [DEFAULTS.WORKLOAD_MIN, DEFAULTS.WORKLOAD_MAX],
    remoteDaysPerWeek: searchType === SearchType.Consultant ?
      [DEFAULTS.REMOTE_DAYS_PER_WEEK_MAX] :
      [DEFAULTS.REMOTE_DAYS_PER_WEEK_MIN],
    languages: [],
    companyName: DEFAULTS.COMPANY_NAME,
    publishDate: DEFAULTS.PUBLISH_DATE,
    onlyManagerConsultants: DEFAULTS.ONLY_MANAGER_CONSULTANTS,
    consultantId: DEFAULTS.CONSULTANT_ID,
    assignmentId: DEFAULTS.ASSIGNMENT_ID
  }
}

const validationSchema = Yup.object().shape({
  availableFrom: Yup.date()
    .nullable()
    .typeError('general.errors.invalidDate')
    .min(new DateBuilder().toMidnightUTC(), 'general.errors.invalidDate')
    .max(new Date('2099-12-31'), 'general.errors.invalidDate')
})

export enum SearchType {
  Consultant,
  Assignment
}

type FilterProps = {
  performSearch: (body: FormValues, currentPage: number) => Promise<void>
  searchType: SearchType
  assignmentOptions?: Options
  consultantOptions?: Options
};

export default function Filter({
  performSearch,
  searchType,
  assignmentOptions,
  consultantOptions
}: FilterProps) {
  const { isUserRoleAdmin, isUserRoleManager } = useAuthContext();
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)

  const [filter, setFilter] = useQueryParam('filter')
  const { t } = useTranslation()

  const skills = useSkillOptionsQuery()
  const { data: skillOptions = [] } = skills
  const roles = useRoleOptionsQuery()
  const { data: roleOptions = [] } = roles
  const languages = useLanguageOptionsQuery()
  const { data: languageOptions = [] } = languages

  const getFilterValues = (): FormValues => {
    return filter[0] ?? getDefaultValues(searchType)
  }

  const [initialValues, setInitialValues] = useState(getFilterValues())

  // keeps track of the source of the last filter change as to not perform too many searches
  const filterChangeSourceRef = useRef<FilterChangeSource>('browser navigation or pagination event')

  // Logic for initial search and all subsequent updates triggered by navigation and pagination
  // events since these events change the filter variable, which is a wrapper for searchParams
  useEffect(() => {
    if (filterChangeSourceRef.current === 'browser navigation or pagination event') {
      const filterValues = getFilterValues()
      setInitialValues(filterValues)
      performSearch(filterValues, filter[1])
    }
    filterChangeSourceRef.current = 'browser navigation or pagination event'
  }, [filter]); // filter changes when pagination is pressed or browser navigation is used

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    filterChangeSourceRef.current = ''
    if (isDefault(values, searchType)) {
      setFilter(null, DEFAULT_PAGE)
    } else {
      setFilter(values, DEFAULT_PAGE)
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    await performSearch(values, DEFAULT_PAGE)
    setSubmitting(false)
  }

  // resetForm() resets the form to the current value of initialValues, so we need to set
  // initialValues to defaultValues in case the component was mounted with filterValues
  // as it's initialValues
  const onResetFilter = (resetForm: () => void) => () => {
    filterChangeSourceRef.current = ''
    const defaultValues = getDefaultValues(searchType)
    setInitialValues(defaultValues)
    resetForm()
    setFilter(null, DEFAULT_PAGE)
    performSearch(defaultValues, DEFAULT_PAGE)
  }

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit, isSubmitting, resetForm } = formikProps
        return (
          <Form onSubmit={handleSubmit}>
            <ScrollToError />
            <Box mb={5}>
              <Sticky enabled={true} top={20} bottomBoundary={1200} innerZ={1}>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    bgcolor: 'common.white',
                    boxShadow: '0px -10px 0px 15px #FFFFFF, 0px 10px 0px 15px #FFFFFF',
                    '&:hover': {
                      boxShadow: '0px -10px 0px 15px #FFFFFF, 0px 10px 0px 15px #FFFFFF',
                    },
                  }}
                >
                  <Button
                    id="search"
                    ref={submitBtnRef}
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    endIcon={<SearchIcon />}
                    sx={{
                      minWidth: '40px',
                      padding: '7px 12px',
                    }}
                  >
                    {t('general.search')}
                  </Button>
                  <Tooltip title={t('general.resetFilter')} placement="top" arrow>
                    <IconButton aria-label="reset filter" id="reset" onClick={onResetFilter(resetForm)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Sticky>
            </Box>
            {searchType === SearchType.Assignment && (consultantOptions || []).length > 0 && (
              <Box mt={-2} mb={0.5}>
                <SelectField
                  name="consultantId"
                  id="consultant-id"
                  customLabel={t('consultantListings.consultantId')}
                  options={consultantOptions}
                  fullWidth
                  placeholder={t('Common.ConsultantPlaceholder')}
                />
              </Box>
            )}
            {searchType === SearchType.Consultant && (assignmentOptions || []).length > 0 && (
              <Box mt={-2} mb={0.5}>
                <SelectField
                  name="assignmentId"
                  id="assignment-id"
                  customLabel={t('consultantListings.assignmentId')}
                  options={assignmentOptions}
                  fullWidth
                  placeholder={t('Common.AssignmentPlaceholder')}
                />
              </Box>
            )}
            {searchType === SearchType.Consultant && isUserRoleAdmin && isUserRoleManager && (
              <Box mb={0.5}>
                <CheckboxField
                  name="onlyManagerConsultants"
                  id="only-manager-consultants"
                  label={t('consultantListings.onlyManagerConsultants')}
                  color="secondary"
                  value={true}
                />
              </Box>
            )}
            <Box mb={0.5}>
              <AutocompleteField
                name="skills"
                id="skills"
                label={t('Common.Skills')}
                options={skillOptions}
                chipSx={{ maxWidth: { xs: 180, sm: 220 } }}
                placeholder={t('Common.SkillsPlaceholder')}
              />
            </Box>
            <Box mb={0.5}>
              <AutocompleteField
                name="roles"
                id="roles"
                label={t('jobListings.roles')}
                options={roleOptions}
                // @ts-ignore
                getOptionLabel={(option) => option?.jobTitle || option?.name}
                placeholder={t('Common.RolesPlaceholder')}
              />
            </Box>
            {searchType === SearchType.Assignment && (
              <Box mb={0.5}>
                <InputField
                  name="companyName"
                  id="company-name"
                  customLabel={t('jobListings.company')}
                  placeholder={t('Common.CompanyPlaceholder')}
                  fullWidth
                />
              </Box>
            )}
            <Box mb={0.5}>
              <GoogleAutocompleteField
                name="location"
                id="location"
                customLabel={t('jobListings.location')}
                placeholder={t('Common.LocationPlaceholder')}
                fullWidth
              />
            </Box>
            {searchType === SearchType.Assignment && (
              <Box mb="18px">
                <SliderField
                  label={t('jobListings.commutingDistanceToWork')}
                  name="distanceToWork"
                  id="distance"
                  min={1}
                  max={100}
                  valueLabelFormat={(v) => <span style={{ marginLeft: 4 }}>{v} km</span>}
                />
              </Box>
            )}
            <Box mb={0.5}>
              <SearchDatePickerField
                name="availableFrom"
                id="available-from"
                customLabel={t(
                  searchType === SearchType.Consultant ?
                    'consultantListings.startingDate' :
                    'jobListings.startingDate')}
                fullWidth
              />
            </Box>
            <Box mt={0.5} mb="18px">
              <SearchNumberField
                name="hourlyRate"
                id="hourly-rate"
                customLabel={t(
                  searchType === SearchType.Consultant ?
                    'jobListings.maximumHourlyRate' :
                    'consultant.minimumHourlyRate'
                )}
                placeholder={t(
                  searchType === SearchType.Consultant ?
                    'Common.MaxHourlyRatePlaceholder' :
                    'Common.MinHourlyRatePlaceholder'
                )}
                fullWidth
              />
            </Box>
            <Box mb="18px">
              <SliderField
                label={t('jobListings.workload')}
                name="workLoad"
                id="workload"
                min={10}
                max={100}
                valueLabelFormat={(v) => <span style={{ marginLeft: 4 }}>{v}%</span>}
              />
            </Box>
            <Box mb="18px">
              <SliderField
                label={t(
                  searchType === SearchType.Consultant ?
                    'jobListings.workFromHomeAtMost' :
                    'consultant.workFromHomeAtLeast'
                )}
                name="remoteDaysPerWeek"
                id="remote-days"
                min={0}
                max={5}
                valueLabelFormat={(v) => <span>{v}</span>}
              />
            </Box>
            <Box mb={searchType === SearchType.Consultant ? -2.5 : 0.5}>
              <AutocompleteField
                name="languages"
                id="languages"
                label={t('jobListings.languages')}
                placeholder={t('Common.LanguagePlaceholder')}
                options={languageOptions}
              />
            </Box>
            {searchType === SearchType.Assignment && (
              <SelectField
                name="publishDate"
                id="publish-date"
                customLabel={t('jobListings.publishDate')}
                options={publishDateOptions}
                fullWidth
                hasI18Keys
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

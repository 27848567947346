import React from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import Container from '@mui/material/Container'
import DefaultFallback from '../components/common/DefaultFallback'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import { Stack } from '@mui/material'

export default function AuthLayout() {
  return (
    <Stack
      justifyContent="space-between"
      sx={{ minHeight: '100vh' }}
    >
      <Stack>
        <Header />
        <Container
          maxWidth="xl"
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
        >
          <ErrorBoundary FallbackComponent={DefaultFallback}>
            <Outlet />
          </ErrorBoundary>
        </Container>
      </Stack>
      <Footer />
    </Stack>
  )
}

import React, { useLayoutEffect } from 'react'
import { RouteObject, useLocation } from 'react-router-dom'
import { Role, ROLES } from 'utils/utils'
import RoutePathNames from './routePathNames'
import AuthProvider from 'context/auth-context'
import RequireAuth from './RequireAuth'
import MainLayout from 'layouts/MainLayout'
// auth
import AuthLayout from 'layouts/AuthLayout'
import { getAuthRoutes } from './auth'
// admin
import AdminPrivateLayout from 'layouts/AdminPrivateLayout'
import { getAdminPrivateRoutes, getAdminHeaderLinks } from './admin'
// consultant
import ConsultantPrivateLayout from 'layouts/ConsultantPrivateLayout'
import { getConsultantPrivateRoutes, getConsultantHeaderLinks } from './consultant'
// company
import CompanyPrivateLayout from 'layouts/CompanyPrivateLayout'
import { getCompanyPrivateRoutes, getCompanyHeaderLinks } from './company'
// manager
import ManagerPrivateLayout from 'layouts/ManagerPrivateLayout'
import { getManagerPrivateRoutes, getManagerHeaderLinks } from './manager'
// import AboutUs from 'pages/about'
// import OurStory from 'pages/story'
// import Career from 'pages/career'
import PrivacyPolicy from 'pages/privacy-policy'
import TermsConditions from 'pages/terms-conditions'
import JobDetails from 'pages/consultant/job-listings/job-details/JobDetails'

// ScrollReset makes sure that whenever you go to a new page, your scroll is reset to
// be at the top of the new page. But this doesn't affect scroll height on previous pages
const ScrollReset = ({ children }: { children: any }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])
  return children
}

const authRoutes = getAuthRoutes()
const adminPrivateRoutes = getAdminPrivateRoutes()
const consultantPrivateRoutes = getConsultantPrivateRoutes()
const companyPrivateRoutes = getCompanyPrivateRoutes()
const managerPrivateRoutes = getManagerPrivateRoutes()

const routes: RouteObject[] = [
  {
    path: RoutePathNames.base,
    element: (
      <ScrollReset>
        <AuthProvider>
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        </AuthProvider>
      </ScrollReset>
    ),
    children: [
      {
        path: RoutePathNames.admin.base,
        element: <AdminPrivateLayout />,
        children: adminPrivateRoutes,
      },
      {
        path: RoutePathNames.consultant.base,
        element: <ConsultantPrivateLayout />,
        children: consultantPrivateRoutes,
      },
      {
        path: RoutePathNames.company.base,
        element: <CompanyPrivateLayout />,
        children: companyPrivateRoutes,
      },
      {
        path: RoutePathNames.manager.base,
        element: <ManagerPrivateLayout />,
        children: managerPrivateRoutes,
      },
    ],
  },
  {
    element: (
      <ScrollReset>
        <AuthProvider>
          <AuthLayout />
        </AuthProvider>
      </ScrollReset>
    ),
    children: authRoutes.concat([
      // {
      //   path: RoutePathNames.about,
      //   element: <AboutUs />,
      // },
      // {
      //   path: RoutePathNames.story,
      //   element: <OurStory />,
      // },
      // {
      //   path: RoutePathNames.career,
      //   element: <Career />,
      // },
      {
        path: RoutePathNames.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: RoutePathNames.termsConditions,
        element: <TermsConditions />,
      }
    ]),
  },
  {
    element: (
      <ScrollReset>
        <AuthProvider>
          <MainLayout />
        </AuthProvider>
      </ScrollReset>
    ),
    children: [
      {
        path: RoutePathNames.consultant.jobListingId,
        element: <JobDetails />,
      }
    ],
  },
]

export const getAllRoutes = () => routes

const mergeAdminHeaderLinks = (
  routes: ReturnType<
    typeof getConsultantHeaderLinks | typeof getCompanyHeaderLinks | typeof getManagerHeaderLinks
  >
) => [...routes, ...getAdminHeaderLinks()]

export const getHeaderLinks = (role?: Role) => {
  if (role === ROLES.CONSULTANT) {
    return mergeAdminHeaderLinks(getConsultantHeaderLinks())
  } else if (role === ROLES.COMPANY) {
    return mergeAdminHeaderLinks(getCompanyHeaderLinks())
  } else if (role === ROLES.MANAGER) {
    return mergeAdminHeaderLinks(getManagerHeaderLinks())
  } else {
    return []
  }
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Container, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import pencilIcon from 'static/svg/pencil.svg'
import CompanyAssignments from './CompanyAssignments'
import VerifyEmailDialog from 'components/common/VerifyEmailDialog'
import { useAuthContext } from 'context/auth-context'
import CoverImage from 'components/common/Cover'
import CloseIcon from '@mui/icons-material/Close'
import { getCompleteProfileReminder, setCompleteProfileReminder } from 'utils/utils'
import { LightTooltip } from 'components/styledComponents/LightTooltip'

export default function CompanyLanding() {
  const { t } = useTranslation()

  const { user, isUserEmailVerified, companyData, isCompanyProfileComplete } = useAuthContext()
  const email = user?.data?.email || ''

  const {
    profile_photo,
    cover_picture,
    company_name = '',
    company_description = '',
    city = '',
    country = ''
  } = companyData || {}

  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(true);
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
    setCompleteProfileReminder(false)
  }

  useEffect(() => {
    if ((isUserEmailVerified || !isVerifyEmailOpen) && getCompleteProfileReminder() && companyData !== undefined) {
      setOpen(!isCompanyProfileComplete)
    }
  }, [companyData, isVerifyEmailOpen, getCompleteProfileReminder])

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ pb: 10 }}
    >
      <CoverImage src={cover_picture} />
      <Container>
        <Box mb={4}>
          <Avatar
            alt="logo"
            src={profile_photo ?? ''}
            sx={{
              width: { xs: 100, sm: 150 },
              height: { xs: 100, sm: 150 },
              borderRadius: 4,
              mt: { xs: '-50px', sm: '-75px' }
            }}
          />
        </Box>

        <Typography variant="h2" mb={2}>
          {company_name ? company_name : <Skeleton width={200} />}
        </Typography>

        <div>
          <ChipWithIcon label={`${city}, ${country}`} img="location" imgType="svg" />
        </div>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.5, sm: 2 }} my={4}>
          <LightTooltip
            arrow
            placement="top"
            open={open}
            title={
              <Stack
                direction="row"
                alignItems="center"
              >
                <Typography >
                  {t('general.completeProfile')}
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ p: '4px' }}
                >
                  <CloseIcon id="close" />
                </IconButton>
              </Stack>
            }
          >
            <Button
              component={Link}
              to={RoutePathNames.company.settings}
              id="edit"
              variant="contained"
              color="secondary"
              size="large"
              startIcon={
                <Avatar
                  alt=""
                  src={pencilIcon}
                  sx={{ width: 16, height: 16, borderRadius: '0', mr: '1px' }}
                />
              }
            >
              {t('ProfileCommon.EditProfile')}
            </Button>
          </LightTooltip>
          <LightTooltip
            arrow
            placement='top'
            title={isCompanyProfileComplete ? '' : t('ProfileCommon.CompleteYourProfileFirst')}
          >
            <div>
              <Button
                component={Link}
                to={RoutePathNames.company.assignment.create}
                id="create-assignment"
                disabled={!isCompanyProfileComplete}
                variant="outlined"
                color="primary"
                size="large"
                startIcon="+"
                sx={{ width: '100%' }}
              >
                {t('Common.CreateAnAssignment')}
              </Button>
            </div>
          </LightTooltip>
          {!isUserEmailVerified && (
            <VerifyEmailDialog
              changeEmailLink={RoutePathNames.company.settings}
              email={email}
              notifyIsOpen={(isOpen: boolean) => setIsVerifyEmailOpen(isOpen)}
            />
          )}
        </Stack>

        <Box mb="60px">
          <Typography
            variant="body"
            sx={{ display: 'inline-block' }}
            color="primary.60"
            maxWidth={626}
          >
            {company_description ? company_description : null}
          </Typography>
        </Box>

        <CompanyAssignments />
      </Container>
    </Container>
  )
}

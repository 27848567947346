import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, useFormikContext } from 'formik'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Paper, Typography } from '@mui/material'
import { generateUniqueId } from 'utils/utils'
import AiEnhancedTextField, { EnhanceType } from '../AiEnhancedTextField'
import { BeforeCapture, DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { getListStyle, handleBeforeCapture, handleDragEnd } from './DragAndDropUtils'
import DragHandleIcon from '@mui/icons-material/DragHandle';

type RoleDescriptionFieldArrayProps = {
  id: string
}
const minimizeSize = 236;

export default function RoleDescriptionFieldArray({ id }: RoleDescriptionFieldArrayProps) {
  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<{ roleDescription: any }>();

  const onDragEnd = (result: DropResult) => {
    const roleDesArray = handleDragEnd(values.roleDescription, result);

    if (roleDesArray !== null) {
      setFieldValue('roleDescription', roleDesArray);
    }
  }

  const onBeforeCapture = (before: BeforeCapture) => {
    handleBeforeCapture(before, minimizeSize);
  }

  return (
    // Do not remove this div, it helps with the consistency of DND
    <div>
      <FieldArray
        name="roleDescription"
        render={({
          form: {
            values: { roleDescription },
          },
          push,
          remove,
        }) => {
          return (
            <DragDropContext
              onDragEnd={onDragEnd}
              onBeforeCapture={onBeforeCapture}
            >
              <Droppable droppableId="roleDescription">
                {(droppableProvided, droppableSnapshot) => (
                  <div
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                  >
                    <Box sx={(theme) => ({ ...getListStyle(theme, droppableSnapshot.isDraggingOver) })}>
                      {roleDescription && roleDescription.map((roledescription: any, index: number) => {
                        if (!roledescription.id) roledescription.id = generateUniqueId();

                        return (
                          <Draggable
                            key={roledescription.id}
                            draggableId={roledescription.id.toString()}
                            index={index}
                            isDragDisabled={roleDescription.length <= 1}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <Paper
                                elevation={draggableSnapshot.isDragging ? 20 : 2}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                sx={{
                                  userSelect: 'none',
                                  padding: 1,
                                  mb: 1,
                                  overflow: 'hidden'
                                }}
                              >
                                <Stack key={roledescription.id} direction="column" spacing={1.5}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    {...draggableProvided.dragHandleProps}
                                    sx={{ height: 36 }}
                                  >
                                    <Typography variant="h5">
                                      {t('Common.RoleDescription')} {index > 0 && index + 1}
                                    </Typography>
                                    {roleDescription.length > 1 && (
                                      <>
                                        <DragHandleIcon />
                                        <Button
                                          id={`${id}-${index}-remove`}
                                          startIcon={<RemoveIcon />}
                                          color="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          {t('Common.Remove')}
                                        </Button>
                                      </>
                                    )}
                                  </Stack>
                                  <AiEnhancedTextField
                                    name={`roleDescription.${index}.description`}
                                    id={`${id}-${index}-description`}
                                    label={t('Enter role description')}
                                    enhanceType={EnhanceType.Assignment}
                                  />
                                </Stack>
                              </Paper>
                            )}
                          </Draggable>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </Box>
                    <Button
                      id={`${id}-add`}
                      startIcon={<AddIcon />}
                      color="secondary"
                      onClick={() => push(createNewRoleDescription())}
                      sx={{ mt: 1 }}
                    >
                      {t('general.addAnotherRoleDesc')}
                    </Button>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
        }}
      />
    </div>
  )
}

export const createNewRoleDescription = () => {
  return {
    id: generateUniqueId(),
    description: ''
  }
}

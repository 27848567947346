import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useMatch, Outlet } from 'react-router-dom'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { enqueueSnackbar } from 'notistack'
import RoutePathNames from 'routes/routePathNames'
import {
  getRoleDescriptionNames,
  setRoleDescriptionNames,
  getRoleNames,
  setRoleNames,
  setLanguageNames,
  getLanguageNames,
} from 'utils/utils'
import { Roles, Skills } from 'api/cvs/queries'
import { LanguageOptions } from 'api/languages/queries'
import { useCompanyByUserIdQuery } from 'api/company/queries'
import { useAssignmentByIdQuery } from 'api/assignments/queries'
import {
  useCreateAssignmentMutation,
  useUpdateAssignmentMutation,
  CreateAssignment,
} from 'api/assignments/mutations'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import { createNewRoleDescription } from 'components/ui/FormikFields/FieldArray/RoleDescriptionFieldArray'
import * as CONSTANTS from '../api/constants/constants'
import ScrollToError from 'components/common/ScrollToError'
import DateBuilder from 'utils/classes/DateBuilder'

export type CreateAssignmentFormValues = {
  jobTitle: string
  location: {
    city: string
    formatted: string
    lat?: number
    lng?: number
  }
  // fullTimePartTime: ''; // select or input ???
  // distance: string | number;
  summary: string
  roleDescription: { description: string }[]
  skills: Skills
  roles: Roles
  languages: LanguageOptions
  totalYearsExperience: string | number
  workLoad: string | number
  remoteDaysPerWeek: string | number
  hourlyRate: {
    price: string | number
    currency: string
  }
  availableFrom: Date | string
  duration: string | number
  applicationDeadline: Date | string
  createdAt: number
  fkUserId?: number | string
}

export default function AssignmentLayout() {
  const navigate = useNavigate()

  const location = useLocation()
  const [preview, setPreview] = useState(location?.state?.preview)

  const isCreateAssignmentMatch = useMatch(RoutePathNames.company.assignment.create)
  const isPreviewAssignmentMatch = useMatch(RoutePathNames.company.assignment.preview)
  const isEditAssignmentMatch = useMatch(RoutePathNames.company.assignment.edit.assignmentId)
  const isCopyAssignmentMatch = useMatch(RoutePathNames.company.assignment.copy.assignmentId)
  const assignmentId = isEditAssignmentMatch?.params?.assignmentId || isCopyAssignmentMatch?.params?.assignmentId

  const { data: editAssignmentData } = useAssignmentByIdQuery(assignmentId)
  const { body: editAssignmentDataBody } = editAssignmentData || {}

  const { data: companyData } = useCompanyByUserIdQuery()

  const createAssignment = useCreateAssignmentMutation()
  const updateAssignment = useUpdateAssignmentMutation(editAssignmentData?.assignment_id)

  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    jobTitle: Yup.string().required('general.errors.jobTitle'),
    location: Yup.object().shape({
      city: Yup.string().required('general.errors.location.city'),
    }),
    summary: Yup.string().required('assignment.labels.aiHelperText'),
    skills: Yup.array()
      .min(
        CONSTANTS.NUM_SKILLS_MIN,
        t('VerifyCv.addSkills', { count: CONSTANTS.NUM_SKILLS_MIN })
      )
      .test(
        'Min test of primary skills',
        t('VerifyCv.primarySkillsMinTxt', { count: CONSTANTS.NUM_PRIMARY_SKILLS_MIN }),
        (updatedSkills) => {
          if (!updatedSkills) return false
          const primarySkills = updatedSkills.filter(
            (s) => s.isPrimary === CONSTANTS.SKILLRANK_PRIMARY
          )
          return primarySkills.length >= CONSTANTS.NUM_PRIMARY_SKILLS_MIN
        }
      )
      .test(
        'Max test of primary skills',
        t('VerifyCv.primarySkillsMaxTxt', { count: CONSTANTS.NUM_PRIMARY_SKILLS_MAX }),
        (updatedSkills) => {
          if (!updatedSkills) return false
          const primarySkills = updatedSkills.filter(
            (s) => s.isPrimary === CONSTANTS.SKILLRANK_PRIMARY
          )
          return primarySkills.length <= CONSTANTS.NUM_PRIMARY_SKILLS_MAX
        }
      ),
    roles: Yup.array().min(1, 'general.errors.roles'),
    languages: Yup.array().min(1, 'general.errors.languages'),
    totalYearsExperience: Yup.number()
      .min(CONSTANTS.TOTAL_YEARS_EXPERIENCE_MIN)
      .max(CONSTANTS.TOTAL_YEARS_EXPERIENCE_MAX, 'general.errors.totalYearsExperienceError')
      .required('general.errors.totalYearsExperience'),
    workLoad: Yup.number()
      .min(CONSTANTS.WORKLOAD_MIN)
      .max(CONSTANTS.WORKLOAD_MAX, 'general.errors.workLoadError')
      .required('general.errors.workLoad'),
    remoteDaysPerWeek: Yup.number()
      .min(CONSTANTS.REMOTE_DAYS_PER_WEEK_MIN)
      .max(CONSTANTS.REMOTE_DAYS_PER_WEEK_MAX, 'general.errors.remoteDaysPerWeek')
      .required('general.errors.remoteDaysPerWeekRequired'),
    hourlyRate: Yup.object().shape({
      price: Yup.number().required('general.errors.hourlyRate.price'),
    }),
    availableFrom: Yup.date()
      .required('general.errors.availableFrom')
      .typeError('general.errors.invalidDate')
      .min(new DateBuilder().toMidnightUTC().adjustDays(1), 'general.errors.invalidDate')
      .max(new DateBuilder('2100-10-10').toMidnightUTC(), 'general.errors.invalidDate'),
    duration: Yup.number().required('general.errors.duration'),
    applicationDeadline: Yup.date()
      .required('general.errors.applicationDeadline')
      .typeError('general.errors.invalidDate')
      .min(new DateBuilder().toMidnightUTC(), 'general.errors.invalidDate')
      .test(
        'is-before-available-from',
        'general.errors.applicationDeadlineBeforeAvailableFrom',
        function (value) {
          const { availableFrom } = this.parent;
          if (value && availableFrom) {
            console.log(value, availableFrom)
            return new DateBuilder(value).toMidnightUTC().getTime() < new DateBuilder(availableFrom).toMidnightUTC().getTime();
          }
          return true;
        }
      ),
  })

  const onSubmit = async (
    values: CreateAssignmentFormValues,
    { setSubmitting }: FormikHelpers<CreateAssignmentFormValues>
  ) => {
    if (isCreateAssignmentMatch || isCopyAssignmentMatch) {
      const preview = {
        ...values,
        createdAt: Date.now(),
      }
      setPreview(preview)
      navigate(RoutePathNames.company.assignment.preview, {
        state: {
          preview,
        },
      })
      return
    }
    if (companyData?.company_name) {
      const {
        location: { city, formatted, lat, lng },
        roleDescription,
        roles,
        languages,
        hourlyRate: { price },
        availableFrom,
        applicationDeadline,
        ...restValues
      } = values

      const body = {
        ...restValues,
        name: {
          raw: companyData.company_name,
        },
        profile_photo: companyData?.profile_photo || '',
        coordinates: {
          lat,
          lon: lng,
        },
        location: {
          city,
          formatted,
        },
        roleDescription: getRoleDescriptionNames(roleDescription),
        roles: getRoleNames(roles),
        languages: getLanguageNames(languages),
        hourlyRate: {
          price,
          currency: 'SEK',
        },
        availableFrom: typeof availableFrom === 'object' ? availableFrom.getTime() : '',
        applicationDeadline:
          typeof applicationDeadline === 'object' ? applicationDeadline.getTime() : '',
        skills: restValues.skills.map(({ name, isPrimary }) => ({ name, isPrimary, type: '' })),
      }

      try {
        // Create or copy
        if (isPreviewAssignmentMatch) {
          await createAssignment.mutateAsync(body as CreateAssignment, {
            onSuccess: () => {
              enqueueSnackbar(t('assignment.assignmentIsCreated'), { variant: 'success' })
            },
          })
          // Edit
        } else if (isEditAssignmentMatch) {
          body.fkUserId = editAssignmentData?.body?.fkUserId || ''
          await updateAssignment.mutateAsync(body as CreateAssignment, {
            onSuccess: () => {
              enqueueSnackbar(t('assignment.assignmentIsUpdated'), { variant: 'success' })
            },
          })
        }
        setSubmitting(false)
        navigate(RoutePathNames.company.home)
      } catch (error) {
        setSubmitting(false)
      }
    }
  }

  const initialValues = useMemo(() => {
    if (!assignmentId && preview) {
      return preview
    }

    const {
      jobTitle = '',
      location: { city = '', formatted = '' } = {},
      coordinates: { lat = undefined, lon = undefined } = {},
      summary = '',
      roleDescription = [],
      skills = [],
      roles = [],
      languages = [],
      totalYearsExperience = '',
      workLoad = '',
      remoteDaysPerWeek = '',
      hourlyRate: { price = '' } = {},
      availableFrom,
      duration = '',
      applicationDeadline,
      createdAt = Date.now(),
    } = editAssignmentDataBody || {}

    return {
      jobTitle,
      location: {
        city,
        formatted,
        lat,
        lng: lon,
      },
      summary,
      roleDescription:
        roleDescription?.length > 0
          ? setRoleDescriptionNames(roleDescription)
          : [createNewRoleDescription()],
      skills,
      roles: setRoleNames(roles),
      languages: setLanguageNames(languages),
      totalYearsExperience,
      workLoad,
      remoteDaysPerWeek,
      hourlyRate: {
        price,
      },
      availableFrom:
        isCopyAssignmentMatch
          ? null
          : availableFrom
            ? new DateBuilder(availableFrom).toMidnightUTC()
            : null,
      duration,
      applicationDeadline:
        isCopyAssignmentMatch
          ? null
          : applicationDeadline
            ? new DateBuilder(applicationDeadline).toMidnightUTC()
            : null,
      createdAt,
    }
  }, [assignmentId, preview, editAssignmentDataBody, isCopyAssignmentMatch])

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  return (
    <Formik
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        return (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              manualSubmitForm(formikProps)()
            }}
          >
            <ScrollToError />
            <Outlet />
          </Form>
        )
      }}
    </Formik>
  )
}

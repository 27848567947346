import React from 'react'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography, styled, tooltipClasses, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import RoutePathNames from 'routes/routePathNames';
import { StyledScrollBox } from 'styles/component.styles';
import { getMatchColor, getMatchLabel } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const listItemPadding = 4;
const listYPadding = 7;
type Size = 'small' | 'medium' | 'large' | number;

// TODO update type
export type Applicant = {
  name: string
  profile_photo: string
  user_id: string | number
  match?: number
}

type ApplicantListProps = {
  applicants: Applicant[]
  size?: Size | { xs?: Size, sm?: Size, md?: Size, lg?: Size, xl?: Size }
  max?: number | { xs?: number, sm?: number, md?: number, lg?: number, xl?: number }
  title?: string
  listSize?: number
}

export default function ApplicantList({
  applicants,
  size,
  max,
  title,
  listSize
}: ApplicantListProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const getMaxBasedOnScreenSize = () => {
    if (typeof max === 'number') return max;
    if (typeof max === 'object') {
      if (xl && max.xl !== undefined) return max.xl;
      if (lg && max.lg !== undefined) return max.lg;
      if (md && max.md !== undefined) return max.md;
      if (sm && max.sm !== undefined) return max.sm;
      if (xs && max.xs !== undefined) return max.xs;
    }
    return 4; // default value if no breakpoints match
  };

  const getSizeBasedOnScreenSize = () => {
    if (typeof size === 'number' || typeof size === 'string') return size;
    if (typeof size === 'object') {
      if (xl && size.xl !== undefined) return size.xl;
      if (lg && size.lg !== undefined) return size.lg;
      if (md && size.md !== undefined) return size.md;
      if (sm && size.sm !== undefined) return size.sm;
      if (xs && size.xs !== undefined) return size.xs;
    }
    return 'medium'; // default value if no breakpoints match
  }

  const parsedSize = getSizeBasedOnScreenSize();

  let defaultListSize: number;
  let pxSize: number;

  switch (parsedSize) {
    case 'small':
      pxSize = 24
      defaultListSize = 6
      break
    case 'medium':
      pxSize = 40
      defaultListSize = 5
      break
    case 'large':
      pxSize = 56
      defaultListSize = 4
      break
    default:
      pxSize = parsedSize
      defaultListSize = 5
  }

  if (!listSize) listSize = defaultListSize;
  const listItemHeight = pxSize + listItemPadding * 2;
  const listHeight = listItemHeight * listSize + listYPadding * 2;

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      color: 'rgba(0, 0, 0, 0.87)',
      width: pxSize * 4 + 120,
      maxWidth: 'none',
      boxShadow: '4px 4px 12px 0px rgba(50, 50, 50, 0.25)',
      '& .MuiTooltip-arrow:before': {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #dadde9',
      },
      '& .MuiListItemAvatar-root': {
        minWidth: pxSize + 8,
      },
      '& .MuiButtonBase-root': {
        padding: `${listItemPadding}px 10px`,
      },
      '& .MuiList-root': {
        padding: `${listYPadding}px 0`,
      },
    },
  }))


  return (
    <HtmlTooltip
      // this is needed for links to work on firefox
      disableFocusListener
      title={
        <>
          {title &&
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                paddingTop: '8px'
              }}
            >
              <Typography
                fontWeight={700}
                color="common.black"
                fontSize={pxSize / 4 + 6}
              >
                {title}
              </Typography>
            </Box>
          }
          <StyledScrollBox sx={{ maxHeight: listHeight, overscrollBehavior: 'contain' }}>
            <List dense >
              {applicants.map(({ name, profile_photo, user_id, match }) => (
                <Link
                  key={user_id}
                  to={`${RoutePathNames.consultant.publicProfile}/${user_id}`}
                >
                  <ListItem disablePadding sx={{ height: listItemHeight }} >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={name}
                          src={profile_photo}
                          sx={{
                            width: pxSize,
                            height: pxSize,
                          }}
                        />
                      </ListItemAvatar>
                      <Stack spacing={-0.5} sx={{ overflow: 'hidden', maxHeight: pxSize }}>
                        <ListItemText
                          id={`name-${user_id}`}
                          primary={name}
                          primaryTypographyProps={{
                            fontSize: pxSize / 4 + 6,
                            noWrap: true
                          }}
                        />
                        {match !== undefined &&
                          <ListItemText
                            id={`match-${user_id}`}
                            primary={t('landing.match', { matchLabel: t(getMatchLabel(match)) })}
                            primaryTypographyProps={{
                              fontSize: pxSize / 4 + 4,
                              noWrap: true,
                              color: getMatchColor(match)
                            }}
                          />
                        }
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </StyledScrollBox>
        </>
      }
      arrow
      placement="top"
    >
      <AvatarGroup
        max={getMaxBasedOnScreenSize()}
        sx={{
          '& > .MuiAvatar-root': {
            width: pxSize,
            height: pxSize,
            marginLeft: 0.8,
            marginRight: `-${pxSize / 6 + 8}px`,
            fontSize: pxSize / 2
          },
        }}
      >
        {applicants.map(({ name, user_id, profile_photo }) => (
          <Avatar key={user_id} src={profile_photo} alt={name} variant="circular" />
        ))}
      </AvatarGroup>
    </HtmlTooltip>
  );
}

import React from 'react'
import { Assignments } from 'api/assignments/queries'
import { useCompanyListingsQuery } from 'api/company/queries'
import useListingTabsSearchParams from 'hooks/useListingTabsSearchParams'
import ListingTabs from 'components/common/ListingTabs'
import AssignmentCardLanding from 'components/AssignmentCardLanding/AssignmentCardLanding'

export default function CompanyAssignments() {
  const { tab, offset } = useListingTabsSearchParams()

  const companyListings = useCompanyListingsQuery(tab, offset)

  const { data: companyListingsData, isLoading } = companyListings
  const { assignments = [], total = 0, otherTotal = 0 } = companyListingsData || {}

  return (
    <ListingTabs
      isLoading={isLoading}
      list={assignments}
      total={total}
      otherTotal={otherTotal}
      renderItem={(assignment: Assignments[0]) => (
        <AssignmentCardLanding 
          key={assignment.assignment_id}
          assignment={assignment}
          tab={tab}
        />
      )}
    />
  )
}

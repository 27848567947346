import { styled, Box, Button, Typography } from "@mui/material";

export const BlurredText = styled(Typography)((theme) => ({
  color: 'transparent',
  textShadow: '0 0 0.5em #000',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none'
}));

export const ButtonOverlay = styled(Button)((theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1
}));

export const StyledScrollBox = styled(Box)((theme) => ({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '3px',
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-track:active': {
    backgroundColor: '#e5edef',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    backgroundColor: '#b7b7b7',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#b7b7b7',
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#b7b7b7',
  },
}));

import React, { useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Box, PaginationItem, Stack, Tab, Tabs, Typography, CircularProgress } from '@mui/material'
import { TabType } from 'api/types'
import { getPaginationCount } from 'utils/utils'
import useListingTabsSearchParams from 'hooks/useListingTabsSearchParams'
import { StyledPagination } from 'components/common/StyledPagination'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.primary[40],
  lineHeight: '140%',
  minHeight: 61,
  minWidth: 'auto',
  padding: 8,
  fontSize: 16,
  fontWeight: 300,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

type MapTabsIndex<T extends TabType = TabType> = {
  [key in T]: number
}

type MapIndexTabs<T extends TabType = TabType> = {
  [index: string]: T
}

export const tabs: { tab: TabType; label: string }[] = [
  {
    tab: 'active',
    label: 'general.tabActive',
  },
  {
    tab: 'completed',
    label: 'general.tabCompleted',
  },
]

const mapTabsIndex: MapTabsIndex = {
  active: 0,
  completed: 1,
}

const mapIndexTabs: MapIndexTabs = {
  0: 'active',
  1: 'completed',
}

type ListingTabsProps = {
  list?: any[]
  isLoading?: boolean
  total?: number
  otherTotal?: number
  renderItem?: (item: any) => React.ReactNode
}

export default function ListingTabs({
  list = [],
  isLoading = false,
  total = 0,
  otherTotal = 0,
  renderItem,
}: ListingTabsProps) {
  const { tab, page, setSearchParams } = useListingTabsSearchParams()

  const paginationCount = getPaginationCount(total || otherTotal || 0)

  const [tabVal, setTabVal] = React.useState<number>(mapTabsIndex[tab])

  const onChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabVal(newValue)
    setSearchParams({ tab: mapIndexTabs[newValue], page: '1' })
  }

  useEffect(() => {
    const searchTabVal = mapTabsIndex[tab]
    if (tabVal !== searchTabVal) {
      setTabVal(searchTabVal)
    }
  }, [tabVal, tab])

  const getTabItemCount = useCallback(
    (currTab: TabType) => (
      <Typography
        component="span"
        sx={(theme) => ({
          display: 'inline-block',
          background: theme.palette.secondary[20],
          padding: '4px 5px 3px',
          color: theme.palette.primary.main,
          fontSize: 9,
          lineHeight: '100%',
          borderRadius: theme.shape.rounded,
          boxSizing: 'border-box',
        })}
      >
        {tab === currTab ? total : otherTotal || 0}
      </Typography>
    ),
    [tab, total, otherTotal]
  )

  const { t } = useTranslation()

  return (
    <>
      <Stack flexDirection="row" flexWrap="wrap" alignItems="stretch" mb={4}>
        <Typography
          width={{ md: 'auto', xs: '100%' }}
          variant="h3"
          flexGrow={0}
          pr={7.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: '124%',
          }}
        >
          {t('CompanyLanding.YourListings')}
        </Typography>
        <Box
          sx={(theme) => ({ borderBottom: 1, borderColor: theme.palette.primary[20] })}
          flexGrow={1}
        >
          <StyledTabs value={tabVal} onChange={onChangeTabs} aria-label="listings tabs">
            {tabs.map(({ tab, label }, index) => (
              <StyledTab
                key={tab}
                label={t(label)}
                icon={getTabItemCount(tab)}
                iconPosition="end"
                {...a11yProps(index)}
              />
            ))}
          </StyledTabs>
        </Box>
        {list.length > 0 ? (
          <Box
            width={{ md: 'auto', xs: '100%' }}
            sx={(theme) => ({
              flexGrow: 0,
              borderBottom: 1,
              borderColor: theme.palette.primary[20],
              display: 'flex',
              alignItems: 'center',
              py: 1.5,
            })}
          >
            <StyledPagination
              id="pagination"
              page={page}
              count={paginationCount}
              shape="rounded"
              color="secondary"
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`${`?tab=${tab}&page=${item.page}`}`}
                  {...item}
                />
              )}
            />
          </Box>
        ) : null}
      </Stack>
      {tabs.map((t, index) => (
        <TabPanel key={t.tab} value={tabVal} index={index}>
          <Stack
            gap={2}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {isLoading ? (
              <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <CircularProgress color="secondary" />
              </Box>
            ) : tabVal === index && list?.length && renderItem ? (
              list.map(renderItem)
            ) : null}
          </Stack>
        </TabPanel>
      ))}
    </>
  )
}

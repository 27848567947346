import React from 'react'
import { useMutation } from 'react-query'
import { api } from '../api'
import { pageLimitCount } from '../../utils/utils'
import { CreateCvDto, cvsKeys, Skills, UserCvsBody } from './queries'
import { AxiosRequestConfig } from 'axios'

export type CvSearchParams = {
  assignmentId?: number;
  availableFrom?: number;
  workLoad?: Array<number>;
  maxHourlyRate?: number;
  maxRemoteDaysPerWeek?: number;
  languages?: Array<string>;
  coordinates?: { lat: number; long: number };
  skills?: Array<string>;
  roles?: Array<string>;
}

export type CvSearchResult = {
  data: {
    user_id: number
    assignment_match: number
    search_match: number
    body: {
      fkUserId: number
      jobTitle: string
      availableFrom: number
      workLoad: number
      remoteDaysPerWeek: number
      roles: string[]
      skills: Skills
      name: { raw: string }
      createdAt: number
      duration: number
      location: { city: string }
      profile_photo: string | null
      profession: string
    }
  }[]
  paginationParams: {
    limit: 10
    offset: 0
    total: number
  }
}

const uploadCv = async (formData: FormData, progressCallback?: (progress: number) => void): Promise<CreateCvDto> => {
  const config: AxiosRequestConfig<FormData> = progressCallback ? {
    onUploadProgress: (progressEvent) => {
      const { loaded, total = 1 } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total)
      progressCallback(percentCompleted)
    }
  } : {}

  const response = await api.post('cvs', formData, config)
  return response.data
}

const cvsSearch = async ({
  body,
  offset = 0,
  limit = pageLimitCount,
}: {
  body: CvSearchParams
  offset: number
  limit: number
}): Promise<CvSearchResult> => {
  const response = await api.post(`company/search?offset=${offset}&limit=${limit}`, body)
  return response.data
}

export const useUploadCvMutation = (progressCallback?: (progress: number) => void) => useMutation(cvsKeys.cv(),
  (formData: FormData) => uploadCv(formData, progressCallback))
export const useCvsSearchMutation = () => useMutation(cvsKeys.search, cvsSearch)

import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { format, formatDistanceToNow } from 'date-fns'
import {
  Container,
  Avatar,
  Box,
  Skeleton,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  Tooltip,
  Divider,
  Link as MuiLink,
  Tab,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RoutePathNames from 'routes/routePathNames'
import { getWorkloadStatus, getRemoteDaysPerWeekStatus, checkIsSkillPrimary, getMatchColor, getMatchLabel } from 'utils/utils'
import { assignmentsKeys, useApplicantListQuery, useApplicantStatusQuery, useAssignmentByIdQuery } from 'api/assignments/queries'
import { useCompanyByUserIdQuery } from 'api/company/queries'
import { useConsultantApplyForJobMutation } from 'api/user/mutations'
import Chip from 'components/ui/Chip'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import rolelistIcon from 'static/svg/rolelist.svg'
import { enqueueSnackbar } from 'notistack'
import { RWebShare } from 'react-web-share'
import IosShareIcon from '@mui/icons-material/IosShare';
import CheckIcon from '@mui/icons-material/Check'
import { RiMailSendLine } from 'react-icons/ri'
import ManagerConsultantListings from 'components/manager/ManagerConsultantListings'
import { useAuthContext } from 'context/auth-context'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useConsultantOptionsQuery } from 'api/manager/queries'
import AddIcon from '@mui/icons-material/Add';
import MultipleSelectField from 'components/ui/FormikFields/MultipleSelectField'
import { MutateOptions, useQueryClient } from 'react-query'
import { useManagerApplyForJobMutation } from 'api/manager/mutations'
import ApplicantList from 'components/common/ApplicantList'
import CoverImage from 'components/common/Cover'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ApplicationHandler, { Applicant, ApplicantStatus, ApplicantStatusIds } from 'components/applicants/ApplicationHandler'
import InterviewInvitation from 'components/applicants/InterviewInvitation'
import usePreventNavigation from 'hooks/usePreventNavigation'
import DateBuilder from 'utils/classes/DateBuilder'

enum TabEnum {
  overview = 'overview',
  applicants = 'applicants',
  matchingConsultants = 'matchingConsultants'
}

const skeletonSkills = Array.from(new Array(10), (_, i) => ({ name: `${i}` }))

const validationSchema = Yup.object().shape({
  consultant_ids: Yup.array().min(1, 'jobListings.selectConsultant')
})

const initialValues = {
  consultant_ids: [] as number[]
}

type FormValues = typeof initialValues

export default function CompleteJobDetails() {
  const { isUserRoleConsultant, isUserRoleManager, isUserRoleCompany, user: { data: userData }, setPreventLogout } = useAuthContext();
  const params = useParams<{ jobListingId: string }>()
  const assignmentId = params?.jobListingId ? parseInt(params.jobListingId) : undefined;

  const { t } = useTranslation()
  const { pathname } = useLocation();
  const queryClient = useQueryClient()
  const { data: assignmentData, isLoading } = useAssignmentByIdQuery(params?.jobListingId ?? '')

  const {
    applied,
    approved,
    body: assignmentBody,
    assignment_id,
    consultant_match,
  } = assignmentData || {}
  //appliedCheck variable is needed to change the state of the button after closing the popup
  const [appliedCheck, setAppliedCheck] = useState(false)

  const {
    fkUserId = '',
    name: { raw: companyName = '' } = {},
    summary = '',
    roleDescription = [],
    createdAt,
    duration = 0,
    location,
    jobTitle = '',
    applicationDeadline,
    workLoad = 0,
    remoteDaysPerWeek = 0,
    skills = [],
    totalYearsExperience = 0,
    roles = [],
    availableFrom = new Date().getTime()
  } = assignmentBody || {}
  const city = location?.city || ''
  const available = format(availableFrom ? new Date(availableFrom) : new Date(), 'yyyy-MM-dd')
  const deadline = format(
    applicationDeadline ? new Date(applicationDeadline) : new Date(),
    'yyyy-MM-dd'
  )

  const { data: prevApplicantStatus } = useApplicantStatusQuery(
    assignmentId,
    isUserRoleCompany ?
      fkUserId === userData?.user_id :
      true
  );

  const [applicantStatus, setApplicantStatus] = useState<ApplicantStatus>({
    inbox: [],
    review: [],
    interview: [],
    reject: [],
    contract: []
  });

  useEffect(() => {
    if (prevApplicantStatus !== undefined) {
      setApplicantStatus({
        inbox: prevApplicantStatus.inbox || [],
        review: prevApplicantStatus.review || [],
        interview: prevApplicantStatus.interview || [],
        contract: prevApplicantStatus.contract || [],
        reject: prevApplicantStatus.reject || [],
      })
    }
  }, [prevApplicantStatus])

  const { data: applicantList, isFetching } = useApplicantListQuery(
    assignmentId,
    (isUserRoleCompany && fkUserId === userData?.user_id) || isUserRoleManager,
  );
  const { data: consultantOptionsData } = useConsultantOptionsQuery(isUserRoleManager);
  // filter out consultants that has already applied for this assignment
  const consultantOptions = consultantOptionsData?.filter(
    option => !applicantList?.some(
      (applicant: Applicant) => applicant.user_id === option.value
    )
  )

  const { data: companyData } = useCompanyByUserIdQuery(fkUserId)
  const { profile_photo, cover_picture, company_description } = companyData || {}
  const consultantApply = useConsultantApplyForJobMutation()
  const managerApply = useManagerApplyForJobMutation()
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const mutateOptions: MutateOptions<any, unknown, any, unknown> = {
    onSuccess: (data) => {
      if (data) {
        if (data?.success) {
          if (isUserRoleManager) {
            const keys = assignmentsKeys.getApplicantsByAssignmentId(assignment_id)
            queryClient.refetchQueries(keys, {
              active: true,
              exact: true
            })
          }
          enqueueSnackbar(t('general.mailSent'), { variant: 'success' })
          setAppliedCheck(true)
        } else if (!data?.success) {
          enqueueSnackbar(t('general.mailProviderError'), { variant: 'error' })
        }
      }
    },
    onSettled: () => {
      closeModal()
    }
  }

  const confirmApplyForAJobModal = async () => {
    if (assignment_id) {
      const body = {
        job_id: assignment_id,
      }
      try {
        await consultantApply.mutateAsync(body, mutateOptions)
      } catch (error) { }
    }
  }

  const onSubmit = async (values: FormValues) => {
    if (assignment_id) {
      const body = {
        assignment_id,
        consultant_ids: values.consultant_ids
      }
      try {
        await managerApply.mutateAsync(body, mutateOptions)
      } catch (error) { }
    }
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState<TabEnum>(TabEnum.overview);

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue }, { replace: true });
  };

  useEffect(() => {
    if (isUserRoleCompany !== undefined
      && isUserRoleManager !== undefined
      && fkUserId
      && userData
    ) {
      const tab = searchParams.get('tab');
      if (tab === TabEnum.applicants && isUserRoleCompany && fkUserId === userData?.user_id) {
        setTabValue(TabEnum.applicants);
      } else if (tab === TabEnum.matchingConsultants && isUserRoleManager) {
        setTabValue(TabEnum.matchingConsultants);
      } else if (tab !== TabEnum.overview) {
        setTabValue(TabEnum.overview);
        setSearchParams({ tab: TabEnum.overview }, { replace: true });
      }
    }
  }, [isUserRoleCompany, isUserRoleManager, fkUserId, userData]);

  const applicantStatusIds = useMemo(() => {
    const result = {} as ApplicantStatusIds;

    const keys = Object.keys(applicantStatus) as Array<keyof ApplicantStatus>
    if (prevApplicantStatus) {
      keys.forEach(key => {
        result[key] = new Set(prevApplicantStatus[key]?.map(applicant => Number(applicant.user_id)) || []);
      })
    } else {
      keys.forEach(key => {
        result[key] = new Set([]);
      })
    }

    return result;
  }, [prevApplicantStatus]);

  const applicantStatusDiff = useMemo(() => {
    const result = {} as ApplicantStatus;
    const keys = Object.keys(applicantStatus) as Array<keyof ApplicantStatus>

    keys.forEach(key => {
      result[key] = applicantStatus[key].filter(
        val => !applicantStatusIds[key].has(Number(val.user_id))
      );
    });

    return result;
  }, [applicantStatusIds, applicantStatus]);

  const unsavedChanges: boolean = useMemo(() =>
    applicantStatusDiff.contract.length
    + applicantStatusDiff.interview.length
    + applicantStatusDiff.review.length
    + applicantStatusDiff.reject.length
    + applicantStatusDiff.inbox.length
    > 0
    , [applicantStatusDiff]);

  usePreventNavigation(unsavedChanges);

  useEffect(() => {
    setPreventLogout(unsavedChanges);

    return () => setPreventLogout(false);
  }, [unsavedChanges])

  const roleDescItems = roleDescription.map((role) => (
    <ListItem key={role} dense={false} sx={{ p: 0, mb: '10px', alignItems: 'normal' }}>
      <ListItemIcon sx={{ minWidth: '24px' }}>
        <Avatar
          alt=""
          src={rolelistIcon}
          sx={{ width: 16, height: 16, mt: '2px', borderRadius: '0' }}
        />
      </ListItemIcon>
      <Typography variant="body" lineHeight={1.4} width="100%" sx={{ whiteSpace: 'break-spaces' }}>
        {role}
      </Typography>
    </ListItem>
  ))

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ pb: 10 }}
    >
      <CoverImage src={cover_picture} />
      <Dialog
        fullWidth
        open={modalIsOpen}
        onClose={closeModal}
        PaperProps={{ sx: { maxWidth: { xs: 440, sm: 520 }, width: '100%', m: 2 } }}
      >
        <DialogContent sx={{ px: { xs: 1, sm: 6 }, py: 4 }}>
          <IconButton
            onClick={closeModal}
            sx={{
              p: 0,
              top: 16,
              right: 16,
              position: 'absolute',
            }}
          >
            <CloseIcon id="close" sx={{ fontSize: '18px' }} />
          </IconButton>
          <Typography variant="h3" align="center" mb={2}>
            {t('jobListings.jobApplication')}
          </Typography>
          <Divider sx={{ mb: 4 }} />
          {isUserRoleManager
            ?
            <>
              <Typography
                variant="body"
                align="center"
                color="text.secondary"
                display="inline-block"
                mb={4}
              >
                {t('jobListings.whenYourConsultantsApply', { companyName })}
              </Typography>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Box sx={{ pointerEvents: isSubmitting ? 'none' : 'auto' }}>
                    <Form onSubmit={handleSubmit}>
                      <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="center">
                        {consultantOptions && consultantOptions.length > 0 ? (
                          <MultipleSelectField
                            name="consultant_ids"
                            id="consultant-ids"
                            label={t('jobListings.selectApplicants')}
                            options={consultantOptions}
                            fullWidth
                          />
                        ) : (
                          <Box>
                            <Typography>
                              {applicantList && applicantList.length > 0
                                ? t('jobListings.allYourConsultants')
                                : t('jobListings.noConsultants')
                              }
                            </Typography>
                          </Box>
                        )}
                        <Tooltip
                          title={t('general.addConsultant')}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            component={Link}
                            to={RoutePathNames.manager.consultant.create}
                            state={{ pathname }}
                            id='create-consultant'
                            color="primary"
                            size='large'
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
                        <Button
                          id="submit"
                          type="submit"
                          disabled={isSubmitting ||
                            !(consultantOptions && consultantOptions.length > 0)
                          }
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                        >
                          {t(isSubmitting ? 'general.confirming' : 'general.confirm')}
                        </Button>
                      </Box>
                    </Form>
                  </Box>
                )}
              </Formik>
            </>
            :
            <>
              <Typography
                variant="body"
                align="center"
                color="text.secondary"
                display="inline-block"
                mb={4}
              >
                {t('jobListings.whenYouApply', { companyName })}
              </Typography>
              <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
                <Button
                  id="confirm"
                  disabled={consultantApply.isLoading}
                  onClick={confirmApplyForAJobModal}
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                >
                  {t(consultantApply.isLoading ? 'general.confirming' : 'general.confirm')}
                </Button>
              </Box>
            </>
          }
        </DialogContent>
      </Dialog>

      <Container sx={{ mt: { xs: '-50px', sm: '-75px' } }}>
        <Stack spacing={4}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            spacing={4}
          >
            <Stack
              direction={{ xs: 'row', md: 'column' }}
              alignItems={{ xs: 'flex-end', md: 'flex-start' }}
              spacing={4}
            >
              <Avatar
                alt="logo"
                src={profile_photo ?? ''}
                sx={{
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                  borderRadius: 4
                }}
              />
              <Typography
                component={Link}
                to={`${RoutePathNames.company.profile}/${fkUserId}`}
                variant="h4"
                fontWeight="fontWeightLight"
              >
                <MuiLink underline='always'>
                  {companyName ? companyName : <Skeleton width={200} />}
                </MuiLink>
              </Typography>
            </Stack>
            {(isUserRoleConsultant || isUserRoleManager) && assignmentId && applicantStatus.interview.length > 0 &&
              <Stack direction="row" alignItems="flex-end">
                <InterviewInvitation
                  applicantStatus={applicantStatus}
                  companyName={companyName}
                  assignmentId={assignmentId}
                />
              </Stack>
            }
          </Stack>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={4}
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={{ xs: 2, sm: 4 }}
              alignItems="center"
            >
              <Typography variant="h3">{jobTitle ? jobTitle : <Skeleton width={300} />}</Typography>
              {isUserRoleManager && applicantList && applicantList.length !== 0 && (
                <Box paddingRight={2}>
                  <ApplicantList
                    size={{ xs: 'small', sm: 'medium' }}
                    max={{ xs: 4, sm: 5 }}
                    applicants={applicantList}
                    title={t('jobListings.yourApplicants', { count: applicantList.length })}
                  />
                </Box>
              )}
              {isUserRoleCompany && fkUserId === userData?.user_id && !isFetching && (
                applicantList && applicantList.length > 0 ? (
                  <Box paddingRight={2}>
                    <ApplicantList
                      size={{ xs: 'small', sm: 'medium' }}
                      max={{ xs: 4, sm: 5 }}
                      applicants={applicantList}
                      title={t('landing.applicants', { count: applicantList.length })}
                    />
                  </Box>
                ) : (
                  <Typography>
                    {t('landing.noApplicants')}
                  </Typography>
                )
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={{ xs: 2, sm: 4 }}
            >
              {!isLoading && isUserRoleConsultant && (
                applied || appliedCheck ? (
                  <Button
                    id="applied"
                    variant={approved ? 'contained' : 'outlined'}
                    color={approved ? 'secondary' : 'primary'}
                    size="large"
                    startIcon={approved ? <CheckIcon /> : <RiMailSendLine />}
                    sx={{ pointerEvents: 'none', boxShadow: 'none', alignSelf: 'flex-start' }}
                  >
                    {approved ? t('jobListings.approvedJob') : t('jobListings.appliedJob')}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    id="apply"
                    onClick={openModal}
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={applicationDeadline ? applicationDeadline < new DateBuilder().toMidnightUTC().getTime() : false}
                  >
                    {t('jobListings.apply')}
                  </Button>
                )
              )}
              {isUserRoleManager && !isFetching && (
                <Button
                  fullWidth
                  id="apply"
                  onClick={openModal}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={applicationDeadline ? applicationDeadline < new DateBuilder().toMidnightUTC().getTime() : false}
                >
                  {t('jobListings.apply')}
                </Button>
              )}
              <RWebShare
                data={{
                  text: 'Check out this assignment on benchy.se',
                  url: window.location.href,
                  title: jobTitle,
                }}
              >
                <IconButton>
                  {' '}
                  <IosShareIcon id="share" sx={{ color: '#10122D' }} />
                </IconButton>
              </RWebShare>
            </Stack>
          </Stack>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} >
                <Tab label={t('jobListings.overview')} value={TabEnum.overview} />
                {isUserRoleCompany && fkUserId === userData?.user_id && <Tab label={t('jobListings.applicants')} value={TabEnum.applicants} />}
                {isUserRoleManager && <Tab label={t('jobListings.matchingConsultants')} value={TabEnum.matchingConsultants} />}
              </TabList>
            </Box>
            <TabPanel sx={{ padding: 0 }} value={TabEnum.overview}>
              <Stack spacing={4}>
                <Stack direction="row" mb={7.5} gap={1.5} flexWrap="wrap">
                  <ChipWithIcon label={location?.city} img="location" imgType="svg" />
                  <ChipWithIcon
                    label={workLoad ? t(getWorkloadStatus(workLoad)) : ''}
                    img="clock"
                    imgType="svg"
                  />
                  <ChipWithIcon
                    label={t(getRemoteDaysPerWeekStatus(remoteDaysPerWeek))}
                    img="earth"
                    imgType="svg"
                  />
                </Stack>
                <Stack
                  direction={{ md: 'row', xs: 'column' }}
                  spacing={{ md: 0, xs: 4 }}
                  justifyContent="space-between"
                >
                  <Box sx={{ pr: { md: '32px', xs: 0 } }} flexGrow={1} maxWidth="752px">
                    <Typography variant="h4" mb="16px">
                      {t('Common.AboutThisAssignment')}
                    </Typography>
                    <Typography
                      variant="body"
                      sx={{ display: 'block', whiteSpace: 'break-spaces' }}
                      mb="32px"
                    >
                      {summary ? (
                        summary
                      ) : (
                        <>
                          <Skeleton sx={{ mb: 1 }} />
                          <Skeleton sx={{ mb: 1 }} />
                          <Skeleton sx={{ mb: 1 }} />
                        </>
                      )}
                    </Typography>
                    <Typography variant="h4" mb="16px">
                      {t('Common.RoleDescription')}
                    </Typography>
                    <List>{roleDescItems}</List>
                  </Box>
                  <Stack direction="column" spacing={4}>
                    <Box
                      sx={(theme) => ({
                        width: { md: 436, xs: '100%' },
                        bgcolor: theme.palette.common.white,
                        borderRadius: theme.shape.rounded,
                        p: 4,
                      })}
                    >
                      <Stack
                        direction={{ xs: 'column', sm: 'row-reverse' }}
                        justifyContent="space-between"
                        spacing={1.5}
                        mb={1.5}
                      >
                        <Typography variant="h5" color={getMatchColor(consultant_match)}>
                          {isUserRoleConsultant && (
                            t('landing.profileMatch', { matchLabel: t(getMatchLabel(consultant_match)) })
                          )}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="baseline"
                        >
                          <Typography variant="h5" mr="8px">
                            {t('Common.Skills')}
                          </Typography>
                          <Typography variant="body-sm" color="primary.60">
                            {t('Common.SelectedPrimary')}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" mb={3} flexWrap="wrap" gap={1}>
                        {skills.length > 0
                          ? skills.map(({ name, isPrimary }) => (
                            <Chip
                              key={name}
                              variant={checkIsSkillPrimary({ isPrimary }, true) ? 'filled' : 'outlined'}
                              label={name}
                            />
                          ))
                          : skeletonSkills.map(({ name }) => (
                            <Skeleton key={name} width={80}>
                              <Chip label={name} />
                            </Skeleton>
                          ))}
                      </Stack>
                      <Stack
                        direction="row"
                        mb={1.5}
                        alignItems="baseline"
                        justifyContent="space-between"
                      >
                        <Typography variant="h5" mr="8px">
                          {t('Common.Roles')}
                        </Typography>
                      </Stack>
                      <Stack direction="row" mb={3} flexWrap="wrap" gap={1}>
                        {roles.map((role) => (
                          <Chip key={role} label={role} />
                        ))}
                      </Stack>
                      <Stack direction="row" mb={1.5} justifyContent="space-between">
                        <Typography variant="h5">{t('Common.Experience')}</Typography>
                      </Stack>
                      <Typography variant="body" sx={{ display: 'block' }} mb={4} color="primary.60">
                        {totalYearsExperience ? (
                          totalYearsExperience > 1 ? (
                            t('Common.YearsExp', { years: totalYearsExperience })
                          ) : (
                            t('Common.YearExp', { years: totalYearsExperience })
                          )
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </Typography>
                      <Stack direction="row" mb={2} alignItems="center">
                        <Avatar
                          alt="small-logo"
                          src={profile_photo ?? ''}
                          sx={{ width: 48, height: 48, borderRadius: '7px', mr: 1.5 }}
                        />
                        <Stack direction="row" color="primary.40" flexWrap="wrap">
                          <Typography variant="body" color="primary" fontWeight="fontWeightBold" mr={1}>
                            {companyName ? companyName : <Skeleton component="span" width={130} />}
                          </Typography>
                          <Typography fontWeight={300}>
                            {createdAt ? (
                              t('Common.PublishedDaysAgo', {
                                date: formatDistanceToNow(createdAt, { addSuffix: true }),
                              })
                            ) : (
                              <Skeleton width={130} />
                            )}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography variant="body" color="primary.60">
                        {company_description ? company_description : null}
                      </Typography>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        width: { md: 436, xs: '100%' },
                        bgcolor: theme.palette.common.white,
                        borderRadius: theme.shape.rounded,
                        p: 4,
                      })}
                    >
                      <Stack direction="column" spacing={2}>
                        <Typography variant="body">
                          <Trans i18nKey="general.location" city={city}>
                            <strong></strong>: {{ city }}
                          </Trans>
                        </Typography>
                        <Typography variant="body">
                          <Trans i18nKey="general.assignmentStart" available={available}>
                            <strong></strong>: {{ available }}
                          </Trans>
                        </Typography>
                        <Typography variant="body">
                          <Trans i18nKey="general.assignmentLength" count={duration}>
                            <strong></strong>: {{ duration }}
                          </Trans>
                        </Typography>
                        <Typography variant="body">
                          <Trans i18nKey="general.latestDateToAnswer" deadline={deadline}>
                            <strong></strong>: {{ deadline }}
                          </Trans>
                        </Typography>
                        <Typography variant="body">
                          <Trans i18nKey="general.workLoad" count={workLoad}>
                            <strong></strong>: {{ workLoad }}
                          </Trans>
                        </Typography>
                        <Typography variant="body">
                          <Trans i18nKey="general.workFromHome" count={remoteDaysPerWeek}>
                            <strong></strong>: {{ remoteDaysPerWeek }}
                          </Trans>
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={TabEnum.applicants}>
              {assignmentId && prevApplicantStatus && availableFrom &&
                <ApplicationHandler
                  assignment_id={assignmentId}
                  availableFrom={availableFrom}
                  applicantStatusDiff={applicantStatusDiff}
                  applicantStatusIds={applicantStatusIds}
                  unsavedChanges={unsavedChanges}
                  applicantStatus={applicantStatus}
                  setApplicantStatus={setApplicantStatus}
                />
              }
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value={TabEnum.matchingConsultants}>
              <ManagerConsultantListings
                assignmentId={assignmentId}
                title={t('manager.YourMatchingConsultants')}
              />
            </TabPanel>
          </TabContext>
        </Stack>
      </Container>
    </Container>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Stack, Typography } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import Logo from 'static/images/logo.png'

const logoStyle = {
  width: 180,
  height: 30,
  mx: 'auto',
};

export default function Footer() {
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%' }}>
      <Box
        component="footer"
        sx={(theme) => ({
          height: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          bgcolor: theme.palette.primary.main,
          boxSizing: 'border-box',
          color: theme.palette.common.white,
          justifyContent: 'space-between',
        })}
      >
        <Box sx={{ ml: "10%", justifyContent: 'space-between', px: { sm: 10 }, py: { sm: 5 }, display: { xs: 'none', sm: 'block' } }}>
          <Link
            to="/"
          >
            <img
              src={Logo}
              style={logoStyle}
              alt="Logo of Benchy"
            />
          </Link>
        </Box>
        <Stack direction="column" alignItems="left" sx={{ ml: 0.5, mr: "12%", px: { sm: 8 }, py: { sm: 4 }, alignContent: 'center' }}>
          <Typography variant="body2" fontWeight={600} color="white">
            Contact
          </Typography>
          <a href="mailto:hello@benchy.se">
            <Typography variant="body" color="white" sx={{ opacity: 0.7 }}>
              hello@benchy.se
            </Typography>
          </a>
        </Stack>
        <Box
          sx={{
            pt: { xs: 4, sm: 4, },
            px: { sm: 8 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'white',
            mb: 1,
          }}
        >
          <Stack direction="column" spacing={2} sx={{ ml: 0.5, display: { xs: 'block', sm: 'none' } }}>
            <Stack direction="row" spacing={2}>
              <Typography
                component={Link}
                color="white"
                fontWeight="fontWeightBold"
                variant="body-sm"
                to={RoutePathNames.privacyPolicy}
              >
                {t('general.privacyPolicy')}
              </Typography>
              <Typography
                component={Link}
                color="white"
                fontWeight="fontWeightBold"
                variant="body-sm"
                to={RoutePathNames.termsConditions}
              >
                Terms &#38; Conditions
              </Typography>
            </Stack>
            <Typography variant="body-sm" color="white" mt={1}>
              &#xa9;{new Date().getFullYear()} Benchy. {t('general.allRightsReserved')}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2} sx={{ ml: "12%", display: { xs: 'none', sm: 'block' } }}>
            <Stack direction="row" spacing={2}>
              <Typography
                component={Link}
                color="white"
                fontWeight="fontWeightBold"
                variant="body-sm"
                to={RoutePathNames.privacyPolicy}
              >
                {t('general.privacyPolicy')}
              </Typography>
              <Typography
                component={Link}
                color="white"
                fontWeight="fontWeightBold"
                variant="body-sm"
                to={RoutePathNames.termsConditions}
              >
                Terms &#38; Conditions
              </Typography>
            </Stack>
            <Typography variant="body-sm" color="white" mt={1}>
              &#xa9;{new Date().getFullYear()} Benchy. {t('general.allRightsReserved')}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </div >
  )
}

import React from 'react'
import 'styles/main.module.scss'
import 'react-dropzone-uploader/dist/styles.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { QueryClient, QueryCache, MutationCache, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { enqueueSnackbar, closeSnackbar, SnackbarKey, SnackbarProvider } from 'notistack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { getAllRoutes } from './routes'
import { getResponseErrorMsg, isResponseTimeout } from './utils/utils'
import LanguagesProvider from './context/lng-context'
import useReloadEffect from 'hooks/useReloadEffect'

export const action = (snackbarId: SnackbarKey) => (
  <IconButton aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
    <CloseIcon sx={{ color: 'white' }} />
  </IconButton>
)

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const message = getResponseErrorMsg(error)
      // only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        enqueueSnackbar(message, { variant: 'error', action })
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (isResponseTimeout(error)) {
        return
      }
      const message = getResponseErrorMsg(error)
      enqueueSnackbar(message, { variant: 'error', action })
    },
  }),
})

const routes = getAllRoutes()

const router = createBrowserRouter(routes)

function App() {
  useReloadEffect();

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={1}
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <LanguagesProvider>
            <RouterProvider router={router} />
          </LanguagesProvider>
        </SnackbarProvider>
      </LocalizationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App

import React from 'react'
import { Link, useMatch, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
// import CheckIcon from '@mui/icons-material/Check'
import RoutePathNames from 'routes/routePathNames'
import { useSkillOptionsQuery } from 'api/skills/queries'
import { useRoleOptionsQuery } from 'api/roles/queries'
import { useLanguageOptionsQuery } from 'api/languages/queries'
import InputField from 'components/ui/FormikFields/InputField'
import GoogleAutocompleteField from 'components/ui/FormikFields/GoogleAutocompleteField'
import AutocompleteField from 'components/ui/FormikFields/AutocompleteField/AutocompleteField'
import RoleDescriptionFieldArray from 'components/ui/FormikFields/FieldArray/RoleDescriptionFieldArray'
import NumberField from 'components/ui/FormikFields/NumberField/NumberField'
import ResponsiveDatePickerField from 'components/ui/FormikFields/DatePickerField/ResponsiveDatePickerField'
import AiEnhancedTextField, { EnhanceType } from 'components/ui/FormikFields/AiEnhancedTextField'
import DateBuilder from 'utils/classes/DateBuilder'

const getTitle = (action: { create: boolean; edit: boolean; copy: boolean }) => {
  if (action.copy) return 'assignment.copyTitle';
  if (action.edit) return 'assignment.editTitle';
  return 'assignment.createTitle';
};

const getSubtitle = (action: { create: boolean; edit: boolean; copy: boolean }) => {
  if (action.copy) return 'assignment.copySubtitle';
  if (action.edit) return 'assignment.editSubtitle';
  return 'assignment.createSubtitle';
};

const getButtonText = (action: { create: boolean; edit: boolean; copy: boolean }) => {
  if (action.copy) return 'general.previewCopy';
  if (action.edit) return 'general.saveChanges';
  return 'general.previewListing';
};

export default function CreateEditAssignment() {
  const create = !!useMatch(RoutePathNames.company.assignment.create);
  const edit = !!useMatch(RoutePathNames.company.assignment.edit.assignmentId);
  const copy = !!useMatch(RoutePathNames.company.assignment.copy.assignmentId);

  const action = { create, edit, copy };

  const { t } = useTranslation()

  const skills = useSkillOptionsQuery()
  const { data: skillOptions = [] } = skills

  const roles = useRoleOptionsQuery()
  const { data: roleOptions = [] } = roles

  const languages = useLanguageOptionsQuery()
  const { data: languageOptions = [] } = languages

  const { isValid, isSubmitting, getFieldMeta } = useFormikContext();

  return (
    <Container
      maxWidth="lg"
      sx={{
        px: { xs: 1, sm: 6, md: 10, lg: 16 },
        mb: { xs: 4, sm: 10, md: 16 },
        mt: { xs: 2, sm: 4 }
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} mb={4}>
        <div>
          <Typography variant="h3" mb={1}>
            {t(getTitle(action))}
          </Typography>
          <Typography variant="body" color="primary.60" sx={{ display: 'block' }}>
            {t(getSubtitle(action))}
          </Typography>
        </div>
      </Stack>
      <Box
        sx={(theme) => ({
          pointerEvents: isSubmitting ? 'none' : 'auto',
          bgcolor: 'common.white',
          mt: { xs: 2, sm: 4 },
          borderRadius: theme.shape.rounded,
          p: { xs: 1, sm: 4 }
        })}
      >
        <Stack direction="column" spacing={1.5}>
          <InputField
            id="title"
            name="jobTitle"
            label={t('assignment.labels.jobTitle')}
            placeholder={t('assignment.placeholders.jobTitle')}
            fullWidth
          />
          <GoogleAutocompleteField
            id="location"
            name="location"
            label={t('general.labels.location.city')}
            placeholder={t('general.placeholders.city')}
            fullWidth
          />
          <AiEnhancedTextField
            name="summary"
            id="summary"
            label={t('assignment.labels.summary')}
            enhanceType={EnhanceType.Assignment}
          />
          <div>
            <Box mb="12px">
              <RoleDescriptionFieldArray id="role" />
            </Box>
          </div>
          <AutocompleteField
            id="skills"
            name="skills"
            label={t('general.labels.skills')}
            topHelperText={t('general.labels.skillsDescription')}
            options={skillOptions}
            isChipClickable
            isPrimaryDigit
            chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
          />
          <AutocompleteField
            id="roles"
            name="roles"
            label={t('general.labels.roles')}
            options={roleOptions}
            chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
          />
          <AutocompleteField
            id="languages"
            name="languages"
            label={t('general.labels.languages')}
            options={languageOptions}
            chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1.5}
          >
            <NumberField
              id="years-experience"
              name="totalYearsExperience"
              label={t('general.labels.totalYearsExperience')}
              fullWidth
            />
            <NumberField
              id="workload"
              name="workLoad"
              label={t('Common.ExpectedWorkload')}
              fullWidth
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1.5}
          >
            <NumberField
              id="remote-days"
              name="remoteDaysPerWeek"
              label={t('general.labels.workFromHome')}
              fullWidth
            />
            <NumberField
              id="hourly-rate"
              name="hourlyRate.price"
              label={t('general.maximumHourlyRate')}
              fullWidth
            />
          </Stack>
          <ResponsiveDatePickerField
            id="available-from"
            name="availableFrom"
            label={t('assignment.labels.availableFrom')}
            inputFormat="yyyy-MM-dd"
            fullWidth
            minDate={new DateBuilder().toMidnightUTC().adjustDays(1)}
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1.5}
          >
            <NumberField
              id="duration"
              name="duration"
              label={t('assignment.labels.duration')}
              suffix="general.countMonths"
              fullWidth
            />
            <ResponsiveDatePickerField
              id="application-deadline"
              name="applicationDeadline"
              label={t('assignment.labels.applicationDeadline')}
              inputFormat="yyyy-MM-dd"
              fullWidth
              disablePast
              maxDate={
                getFieldMeta<Date>('availableFrom').value ?
                  new DateBuilder(getFieldMeta<Date>('availableFrom').value).toMidnightUTC().adjustDays(-1) :
                  undefined
              }
            />
          </Stack>
          <Stack direction="row" alignItems="stretch" sx={{ mt: '9px' }} spacing={1.5}>
            <Button
              id="submit"
              disabled={!isValid || isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
            >
              {t(getButtonText(action))}
            </Button>
            <Button
              id="cancel"
              variant="outlined"
              size="large"
              component={Link}
              to={RoutePathNames.company.home}
            >
              {t('general.cancel')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  )
}

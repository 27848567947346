import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Avatar, Box, Button, IconButton, Paper, Stack, SxProps, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import ConfirmationDialog from "./ConfirmationDialog";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { StyledScrollBox } from "styles/component.styles";
import RoutePathNames from "routes/routePathNames";
import { Link } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getMatchColor, getMatchLabel, removeKeys } from "utils/utils";
import { UpdateApplicantStatus } from "api/company/mutations";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useUpdateApplicantStatusMutation } from "api/assignments/mutations";
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { assignmentsKeys } from "api/assignments/queries";
import { useQueryClient } from "react-query";
import ApplicantDetailsDialog from "./ApplicantDetailsDialog";
import DateBuilder from "utils/classes/DateBuilder";

export type Applicant = {
  name: string
  profile_photo: string
  user_id: string | number
  match?: number
  accepted_interview?: boolean
}

export interface ApplicantStatus {
  inbox: Applicant[];
  review: Applicant[];
  interview: Applicant[];
  contract: Applicant[];
  reject: Applicant[];
}

export interface ApplicantStatusIds {
  inbox: Set<number>;
  review: Set<number>;
  interview: Set<number>;
  contract: Set<number>;
  reject: Set<number>;
}

const droppableFields: { label: string, key: keyof ApplicantStatus }[] = [
  { label: 'jobListings.inbox', key: 'inbox' },
  { label: 'jobListings.review', key: 'review' },
  { label: 'jobListings.interview', key: 'interview' },
  { label: 'jobListings.contract', key: 'contract' },
  { label: 'jobListings.reject', key: 'reject' },
];

const reorder = (list: Applicant[], startIndex: number, endIndex: number): Applicant[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: Applicant[],
  destination: Applicant[],
  droppableSource: { index: number; droppableId: string },
  droppableDestination: { index: number; droppableId: string }
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: { [key: string]: Applicant[] } = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const itemPadding = 8;

const itemRowHeight = 104;

const itemRowWidth = itemRowHeight * 1.5;

const listPadding = 12;

const listColumnHeight = 400;

const listRowHeight = itemRowHeight + listPadding * 2;

const getItemStyle = (theme: Theme, draggableStyle: React.CSSProperties | undefined): SxProps => ({
  background: theme.palette.background.zinc,
  userSelect: 'none',
  padding: theme.spacing(1),
  marginBottom: { xs: 0, lg: theme.spacing(1) },
  marginRight: { xs: theme.spacing(1), lg: 0 },
  position: 'relative',
  minWidth: { xs: itemRowWidth, lg: 'auto' },
  height: { xs: itemRowHeight, lg: 'auto' },
  maxWidth: { xs: itemRowWidth, lg: 'none' },
  ...draggableStyle,
});

const getListStyle = (theme: Theme, isDraggingOver: boolean): SxProps => ({
  background: isDraggingOver ? theme.palette.background.focus : theme.palette.background.paper,
  overscrollBehavior: { xs: 'auto', lg: 'contain' },
  scrollbarGutter: 'stable',
  borderRadius: theme.spacing(0.375),
  padding: {
    xs: theme.spacing(1.5, 0.5, 0.75, 1.5),
    lg: theme.spacing(1.5, 0.75, 0.5, 1.5)
  },
  height: { xs: listRowHeight, lg: listColumnHeight }
});

type ApplicationHandlerProps = {
  assignment_id: number
  availableFrom: number
  applicantStatusDiff: ApplicantStatus
  applicantStatusIds: ApplicantStatusIds
  unsavedChanges: boolean
  applicantStatus: ApplicantStatus
  setApplicantStatus: Dispatch<SetStateAction<ApplicantStatus>>
}

export default function ApplicationHandler({
  assignment_id,
  availableFrom,
  applicantStatusDiff,
  applicantStatusIds,
  unsavedChanges,
  applicantStatus,
  setApplicantStatus
}: ApplicationHandlerProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const assignmentIsClosed = useMemo(() => availableFrom < new DateBuilder().toMidnightUTC().adjustDays(1).getTime(), [availableFrom]);

  const { mutateAsync } = useUpdateApplicantStatusMutation(assignment_id);

  const [disableDragging, setDisableDragging] = useState<boolean>(false);

  const onDragStart = () => {
    setDisableDragging(true);
  }

  const getList = (key: keyof ApplicantStatus): Applicant[] => applicantStatus[key];

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    setDisableDragging(false);

    if (!(source.droppableId in applicantStatus && destination && destination.droppableId in applicantStatus)) return;

    const sourceId = source.droppableId as keyof ApplicantStatus;
    const destinationId = destination.droppableId as keyof ApplicantStatus;

    if (sourceId === destinationId) {
      const items = reorder(
        getList(sourceId),
        source.index,
        destination.index
      );

      const oldState = removeKeys<ApplicantStatus>(applicantStatus, [sourceId]);

      const newState = {
        [source.droppableId]: items,
        ...oldState
      } as ApplicantStatus;

      setApplicantStatus(newState);
    } else {
      const oldApplicantsState = removeKeys<ApplicantStatus>(applicantStatus, [sourceId, destinationId]);

      const result = move(
        getList(sourceId),
        getList(destinationId),
        source,
        destination
      );

      const newApplicantsState = {
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
        ...oldApplicantsState
      } as ApplicantStatus;

      setApplicantStatus(newApplicantsState);
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const onSave = () => {
    const updateApplicantStatus = {} as UpdateApplicantStatus;

    const keys = Object.keys(applicantStatusDiff) as Array<keyof ApplicantStatus>

    keys.forEach(key => {
      updateApplicantStatus[key] = applicantStatusDiff[key].map((applicant: Applicant) => ({
        user_id: applicant.user_id,
        accepted_interview: applicant.accepted_interview
      }));
    })

    mutateAsync(updateApplicantStatus, {
      onSuccess: () => {
        enqueueSnackbar('Applicants status updated successfully!', { variant: 'success' })

        const keys = assignmentsKeys.getApplicantStatusByAssignmentId(assignment_id);
        queryClient.refetchQueries(keys, {
          active: true,
          exact: true
        })
      },
      onSettled: () => setConfirmDialogOpen(false)
    })
  }

  const [applicantDialogOpen, setApplicantDialogOpen] = useState(false);
  const [applicantId, setApplicantId] = useState<string | number>('');
  const [sourceId, setSourceId] = useState<keyof ApplicantStatus>('inbox');
  const [sourceIndex, setSourceIndex] = useState<number>(0);

  const openApplicantDetails = (id: string | number, key: keyof ApplicantStatus, index: number) => {
    setApplicantId(id);
    setSourceId(key);
    setSourceIndex(index);
    setApplicantDialogOpen(true);
  }

  return (
    <>
      <ApplicantDetailsDialog
        open={applicantDialogOpen}
        onClose={() => setApplicantDialogOpen(false)}
        consultantId={applicantId}
        sourceId={sourceId}
        sourceIndex={sourceIndex}
        onDragEnd={onDragEnd}
        assignmentIsClosed={assignmentIsClosed}
      />
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onSave={onSave}
        applicantsToEmail={applicantStatusDiff}
      />
      <Stack spacing={4}>
        <Box>
          <Typography>
            {t('jobListings.applicantsTitle')}
          </Typography>
        </Box>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
          <Stack direction="row" spacing={0.5}>
            <EmailOutlinedIcon />
            <Typography>- {t('jobListings.willReceiveEmail')}</Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <CheckCircleOutlinedIcon />
            <Typography>- {t('jobListings.interviewAccepted')}</Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <CancelOutlinedIcon />
            <Typography>- {t('jobListings.interviewDeclined')}</Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <HourglassTopTwoToneIcon />
            <Typography>- {t('jobListings.interviewPending')}</Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <OpenInNewIcon />
            <Typography>- {t('jobListings.viewApplicant')}</Typography>
          </Stack>
        </Stack>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            spacing={{ xs: 1, lg: 1 }}
            justifyContent="space-between"
            sx={{ userSelect: 'none' }}
          >
            {droppableFields.map(({ label, key }) => (
              <Box
                key={key}
                sx={{
                  flexGrow: 1,
                  width: { xs: '100%', lg: 0 }
                }}
              >
                <Typography>{t(label)}</Typography>
                <Droppable droppableId={key} direction={lg ? 'vertical' : 'horizontal'}>
                  {(provided, snapshot) => (
                    <Paper
                      component={StyledScrollBox}
                      elevation={2}
                      ref={provided.innerRef}
                      sx={(theme) => ({ ...getListStyle(theme, snapshot.isDraggingOver) })}
                    >
                      <Stack
                        direction={{ xs: 'row', lg: 'column' }}
                        sx={{
                          minHeight: { xs: '100%', lg: listColumnHeight - listPadding * 2 },
                          width: { xs: 'max-content', lg: '100%' },
                          minWidth: '100%',
                        }}
                      >
                        {applicantStatus[key].map((applicant, index) => (
                          <Draggable
                            key={applicant.user_id}
                            draggableId={`${applicant.user_id}`}
                            index={index}
                            isDragDisabled={disableDragging || assignmentIsClosed}
                          >
                            {(provided, snapshot) => (
                              <Paper
                                elevation={snapshot.isDragging ? 8 : 2}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={(theme) => ({
                                  ...getItemStyle(
                                    theme,
                                    provided.draggableProps.style
                                  )
                                })}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Stack
                                    direction={{ xs: 'column', lg: 'row' }}
                                    spacing={1}
                                    sx={{ overflow: 'auto' }}
                                  >
                                    <Avatar src={applicant.profile_photo} />
                                    <Stack sx={{ overflow: 'auto' }}
                                    >
                                      <Typography variant='body-sm' noWrap>
                                        {applicant.name}
                                      </Typography>
                                      <Typography variant="body2" color={getMatchColor(applicant.match)} noWrap>
                                        {t('landing.match', { matchLabel: t(getMatchLabel(applicant.match)) })}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    direction={{ xs: 'row', lg: 'column' }}
                                    alignItems="center"
                                    spacing={{ xs: 0.5, lg: 0 }}
                                    sx={(theme) => ({
                                      position: { xs: 'absolute', lg: 'relative' },
                                      top: { xs: theme.spacing(1), lg: 0 },
                                      right: { xs: theme.spacing(1), lg: 0 },
                                      marginY: { xs: 0, lg: theme.spacing(-1) }
                                    })}
                                  >
                                    {key !== 'inbox' && !applicantStatusIds[key].has(Number(applicant.user_id)) ? (
                                      <EmailOutlinedIcon />
                                    ) : (
                                      <>
                                        {key === 'interview' && applicant.accepted_interview === true &&
                                          <CheckCircleOutlinedIcon />
                                        }
                                        {key === 'interview' && applicant.accepted_interview === false &&
                                          <CancelOutlinedIcon />
                                        }
                                        {key === 'interview' && applicant.accepted_interview === null && applicantStatusIds[key].has(Number(applicant.user_id)) &&
                                          <HourglassTopTwoToneIcon />
                                        }
                                      </>
                                    )}
                                    <IconButton
                                      onClick={() => openApplicantDetails(applicant.user_id, key, index)}
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </Paper>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    </Paper>
                  )}
                </Droppable>
              </Box>
            ))}
          </Stack>
        </DragDropContext>
        <Stack direction="row-reverse">
          <Button
            onClick={() => setConfirmDialogOpen(true)}
            variant="contained"
            color="secondary"
            disabled={!unsavedChanges || assignmentIsClosed}
          >
            {t('jobListings.saveAndSend')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
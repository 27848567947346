import { Box, ButtonBase, List, ListItem, ListItemText } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useUpdateNotificationMutation } from "api/notifications/mutations"
import { StyledScrollBox } from "styles/component.styles"
import { useQueryClient } from "react-query"
import { userKeys } from "api/user/queries"

enum ENotificationType {
  interview_invitation_many = 'interview_invitation_many',
  interview_invitation_one = 'interview_invitation_one',
}

const NotificationTypeToKey: Record<ENotificationType, string> = {
  [ENotificationType.interview_invitation_one]: 'notification.interviewInvitationOne',
  [ENotificationType.interview_invitation_many]: 'notification.interviewInvitationMany'
}

export type Notification = {
  notification_id: number
  fk_user_id: number
  notification_type: ENotificationType
  params: Record<string, any>
  created_at: string
  read: boolean
}

const formatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short"
});

type NotificationHandlerProps = {
  notifications: Notification[]
  reduceCount: () => void;
}

const NotificationHandler: React.FC<NotificationHandlerProps> = ({ notifications, reduceCount }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [notificationsState, setNotificationsState] = useState(notifications);
  const { mutateAsync } = useUpdateNotificationMutation();
  const queryClient = useQueryClient();

  const handleNotificationClick = async (notification_id: number, read: boolean, link?: string) => {
    if (read === false) {
      await mutateAsync({ notification_id, read: true }, {
        onSuccess: () => {
          setNotificationsState((prev) =>
            prev.map((notification) =>
              notification.notification_id === notification_id
                ? { ...notification, read: true }
                : notification
            )
          );
          reduceCount();
          // refetch user data with notifications to avoid displaying stale data
          queryClient.refetchQueries(userKeys.user(), {
            active: true,
            exact: true
          })
        }
      });
    }
    if (link) navigate(link);
  };

  return (
    <StyledScrollBox sx={{ maxWidth: { xs: 300, sm: 400 }, maxHeight: { xs: 400, sm: 500 } }}>
      <List>
        {notificationsState.map((notification, index) => (
          <ListItem
            key={notification.notification_id}
            divider={index !== notificationsState.length-1}
            component={ButtonBase}
            onClick={() => handleNotificationClick(
              notification.notification_id,
              notification.read,
              notification.params.link
            )}
          >
            <Box
              sx={{
                minWidth: '8px',
                minHeight: '8px',
                backgroundColor: notification.read ? "transparent" : "blue",
                borderRadius: "50%",
                marginRight: 2
              }}
            />
            <ListItemText
              primary={t(NotificationTypeToKey[notification.notification_type], notification.params)}
              secondary={formatter.format(new Date(notification.created_at))}
            />
          </ListItem>
        ))}
      </List>
    </StyledScrollBox>
  )
}

export default NotificationHandler;

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Box, Container, Typography, Stack, Button } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import RoutePathNames from 'routes/routePathNames'
import { UserCvsBody, Skills, Roles, WorkExperience, Educations, CreateCvDto } from 'api/cvs/queries'
import { getRoleNames, setLanguageNames, getLanguageNames, testYears, normalizeWorkExperience, normalizeEducation } from 'utils/utils'
import { useAuthContext } from 'context/auth-context'
import { UpdateUser } from 'api/user/queries'
import { useSkillOptionsQuery } from 'api/skills/queries'
import { useRoleOptionsQuery } from 'api/roles/queries'
import { useLanguageOptionsQuery, LanguageOptions } from 'api/languages/queries'
import ScrollToError from 'components/common/ScrollToError'
import UploadAvatarField from 'components/ui/FormikFields/UploadAvatarField'
import { FastTextField } from 'components/ui/FormikFields/InputField'
import GoogleAutocompleteField from 'components/ui/FormikFields/GoogleAutocompleteField'
import { FastNumericFormatField } from 'components/ui/FormikFields/NumberField/NumberField'
import EmploymentsFieldArray from 'components/ui/FormikFields/FieldArray/EmploymentsFieldArray'
import EducationFieldArray from 'components/ui/FormikFields/FieldArray/EducationFieldArray'
import { FastResponsiveDatePickerField } from 'components/ui/FormikFields/DatePickerField/ResponsiveDatePickerField'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import useUploadProfileCoverPhotos from 'hooks/useUploadProfileCoverPhotos'
import AiEnhancedTextField, { EnhanceType } from 'components/ui/FormikFields/AiEnhancedTextField'
import AutocompleteField from 'components/ui/FormikFields/AutocompleteField/AutocompleteField'
import * as CONSTANTS from '../../../api/constants/constants'

type VerifyCvsFormValues = {
  image: {
    url: string
    file: File | null
  }
  name: {
    raw: string
  }
  profession: string
  summary: string
  location: {
    city: string
    formatted?: string
    lat?: number
    lng?: number
  }
  distance: number
  workExperience: WorkExperience
  skills: Skills
  totalYearsExperience: number
  roles: Roles
  education: Educations
  languages: LanguageOptions
  workLoad: number
  remoteDaysPerWeek: number
  availableFrom: Date
  hourlyRate: {
    price: number
  }
}

type CvFormProps = {
  userCvBody?: UserCvsBody
  fileName?: string
}

export default function CvForm({ userCvBody, fileName }: CvFormProps) {
  const {
    user: { data: userData },
    isUserRoleCompany,
    isUserRoleManager,
    isUserRoleConsultant,
    updateConsultant,
    updateConsultantByManager,
    createConsultantCv,
    createConsultantCvByManager,
  } = useAuthContext()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const skills = useSkillOptionsQuery()
  const { data: skillOptions = [] } = skills

  const roles = useRoleOptionsQuery()
  const { data: roleOptions = [] } = roles

  const languages = useLanguageOptionsQuery()
  const { data: languageOptions = [] } = languages

  const initialValueImageUrl = useMemo(() => {
    if (isUserRoleConsultant && userData?.profile_photo) {
      return userData.profile_photo
    } else if (isUserRoleManager && userCvBody?.profile_photo) {
      return userCvBody.profile_photo
    }
    return undefined
  }, [isUserRoleConsultant, isUserRoleManager, userData?.profile_photo, userCvBody?.profile_photo])

  const initialValueSkills = useMemo(
    () =>
      userCvBody && userCvBody?.skills.length > 0
        ? userCvBody.skills.map((skill) => ({
          ...skill,
          isPrimary: skill?.isPrimary ?? CONSTANTS.SKILLRANK_NONPRIMARY,
        }))
        : [],
    [userCvBody]
  )

  const initialValueRoles = useMemo(
    () =>
      userCvBody && userCvBody?.roles?.length > 0
        ? userCvBody.roles.map((role) => ({ name: role }))
        : [],
    [userCvBody]
  )

  const initialValues = useMemo(
    () => ({
      image: {
        url: initialValueImageUrl,
        file: null,
      },
      name: {
        raw: isUserRoleManager
          ? (userCvBody && userCvBody?.name.raw) ?? ''
          : userData?.name ?? '',
      },
      profession: (userCvBody && userCvBody?.profession) ?? '',
      summary: (userCvBody && userCvBody?.summary) ?? '',
      location: {
        formatted: (userCvBody && userCvBody?.location?.formatted) ?? '',
        city: (userCvBody && userCvBody?.location?.city) ?? '',
        lat: (userCvBody && userCvBody?.coordinates?.lat) ?? undefined,
        lng: (userCvBody && userCvBody?.coordinates?.lon) ?? undefined,
      },
      distance: (userCvBody && userCvBody?.distance) ?? CONSTANTS.DISTANCE_TO_WORK_DEFAULT,
      workExperience: normalizeWorkExperience(userCvBody?.workExperience),
      skills: initialValueSkills,
      totalYearsExperience: (userCvBody && userCvBody?.totalYearsExperience) ?? 0,
      roles: initialValueRoles,
      education: normalizeEducation(userCvBody?.education),
      workLoad: (userCvBody && userCvBody?.workLoad) || CONSTANTS.WORKLOAD_DEFAULT,
      remoteDaysPerWeek:
        userCvBody && userCvBody?.remoteDaysPerWeek >= 0 ? userCvBody?.remoteDaysPerWeek : 0,
      availableFrom:
        userCvBody && userCvBody?.availableFrom ? new Date(userCvBody?.availableFrom) : new Date(),
      hourlyRate: {
        price: (userCvBody && userCvBody?.hourlyRate?.price) ?? CONSTANTS.HOURLY_RATE_DEFAULT,
      },
      languages:
        userCvBody && userCvBody?.languages.length > 0
          ? setLanguageNames(userCvBody.languages)
          : [],
    }),
    [
      isUserRoleManager,
      userData,
      userCvBody,
      initialValueImageUrl,
      initialValueSkills,
      initialValueRoles,
    ]
  )

  const { state, pathname } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const isCreateCvPath =
    pathname === RoutePathNames.manager.consultant.create
    || pathname === RoutePathNames.consultant.uploadCvs;

  const isEditCvPath =
    pathname.startsWith(RoutePathNames.manager.consultant.edit)
    || pathname.startsWith(RoutePathNames.consultant.editCvs);

  const navigateOnPrevPage = (replace = false) => {
    if (previousPath) {
      navigate(previousPath, { replace })
    } else {
      navigate(isUserRoleManager ? RoutePathNames.manager.home : RoutePathNames.consultant.home, { replace })
    }
  }

  const { t } = useTranslation()

  const onSubmit = async (
    values: VerifyCvsFormValues,
    { setSubmitting }: FormikHelpers<VerifyCvsFormValues>
  ) => {
    const {
      image,
      location: { city, formatted, lat, lng },
      ...restValues
    } = values

    const fkUserId = userCvBody?.fkUserId

    // try {
    if (isEditCvPath && fkUserId) {
      let profile_photo = ''

      if (isUserRoleConsultant) {
        profile_photo = userData?.profile_photo || ''
      } else if (isUserRoleManager) {
        profile_photo = userCvBody?.profile_photo || ''
      }

      const postData: UpdateUser = {
        cvsBody: {
          ...restValues,
          availableFrom: restValues.availableFrom.getTime(),
          roles: getRoleNames(restValues.roles),
          languages: getLanguageNames(restValues.languages),
          coordinates: {
            lat,
            lon: lng,
          },
          location: {
            city,
            formatted,
          },
          fkUserId,
          profile_photo
        },
      }

      // Photo processing
      const profilePhotoData = await uploadPhoto(image, profile_photo)
      postData.profile_photo = profilePhotoData.photo

      if (isUserRoleManager) {
        updateConsultantByManager({
          consultantId: fkUserId,
          body: postData,
        },
          () => {
            enqueueSnackbar(t('general.consultantCreatedByManagerMsg'), { variant: 'success' })
            navigateOnPrevPage()
            setSubmitting(false)
          }
        )
      } else if (isUserRoleConsultant) {
        updateConsultant(postData,
          () => {
            enqueueSnackbar(t('general.consultantUpdatedMsg'), { variant: 'success' })
            navigateOnPrevPage()
            setSubmitting(false)
          }
        )
      }
    } else if (isCreateCvPath && userCvBody !== undefined) {
      let profile_photo = ''

      if (isUserRoleConsultant) {
        profile_photo = userData?.profile_photo || ''
      } else if (isUserRoleManager) {
        profile_photo = userCvBody?.profile_photo || ''
      }

      // Photo processing
      const profilePhotoData = await uploadPhoto(image, profile_photo)
      userCvBody.profile_photo = profilePhotoData.photo ?? null

      const parsedBody: CreateCvDto = {
        cvsBody: {
          ...userCvBody,
          ...restValues,
          availableFrom: restValues.availableFrom.getTime(),
          roles: getRoleNames(restValues.roles),
          languages: getLanguageNames(restValues.languages),
          coordinates: {
            lat,
            lon: lng,
          },
          location: {
            city,
            formatted,
          }
        },
        fileName: fileName ?? ''
      }

      if (isUserRoleManager) {
        createConsultantCvByManager(parsedBody,
          () => {
            enqueueSnackbar(t('general.consultantCreatedByManagerMsg'), { variant: 'success' })
            navigateOnPrevPage(true)
            setSubmitting(false)
          }
        )
      } else if (isUserRoleConsultant) {
        createConsultantCv(parsedBody,
          () => {
            enqueueSnackbar(t('general.consultantUpdatedMsg'), { variant: 'success' })
            navigateOnPrevPage(true)
            setSubmitting(false)
          }
        )
      }
    }
  }

  const onCancelCv = () => {
    navigateOnPrevPage()
  }

  const {
    validateOnBlur,
    validateOnChange,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const validationSchema = Yup.object().shape({
    name: Yup.object().shape({
      raw: Yup.string()
        .min(CONSTANTS.NAME_LENGTH_MIN, 'VerifyCv.ShortInput')
        .required('VerifyCv.EnterFullName'),
    }),
    profession: Yup.string().required('VerifyCv.EnterPosition'),
    summary: Yup.string().required('VerifyCv.AiHelperText'),
    location: Yup.object().shape({
      city: Yup.string().required('VerifyCv.EnterLocation'),
    }),
    distance: Yup.number()
      .min(
        CONSTANTS.COMMUTE_DISTANCE_MIN,
        t('VerifyCv.MinDistanceTxt', { value: CONSTANTS.COMMUTE_DISTANCE_MIN })
      )
      .max(
        CONSTANTS.COMMUTE_DISTANCE_MAX,
        t('VerifyCv.MaxDistanceTxt', { value: CONSTANTS.COMMUTE_DISTANCE_MAX })
      )
      .required('VerifyCv.EnterDistance'),
    workExperience: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().required('VerifyCv.EnterOrganization'),
        jobTitle: Yup.string().required('VerifyCv.EnterJobTitle'),
        dates: Yup.object().shape({
          years: Yup.string()
            .test({
              name: 'years',
              message: 'general.errors.dates.years',
              test: testYears,
            })
            .required('VerifyCv.EnterYears'),
        }),
        jobDescription: Yup.string().required('VerifyCv.AiHelperText'),
      })
    ),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
          isPrimary: Yup.number().required(),
          name: Yup.string().required(),
        })
      )
      .min(
        CONSTANTS.NUM_SKILLS_MIN,
        t('VerifyCv.addSkills', { count: CONSTANTS.NUM_SKILLS_MIN })
      )
      .test(
        'Min test of primary skills',
        t('VerifyCv.primarySkillsMinTxt', { count: CONSTANTS.NUM_PRIMARY_SKILLS_MIN }),
        (updatedSkills) => {
          if (!updatedSkills) return false
          const primarySkills = updatedSkills.filter(
            (s) => s.isPrimary === CONSTANTS.SKILLRANK_PRIMARY
          )
          return primarySkills.length >= CONSTANTS.NUM_PRIMARY_SKILLS_MIN
        }
      )
      .test(
        'Max test of primary skills',
        t('VerifyCv.primarySkillsMaxTxt', { count: CONSTANTS.NUM_PRIMARY_SKILLS_MAX }),
        (updatedSkills) => {
          if (!updatedSkills) return false
          const primarySkills = updatedSkills.filter(
            (s) => s.isPrimary === CONSTANTS.SKILLRANK_PRIMARY
          )
          return primarySkills.length <= CONSTANTS.NUM_PRIMARY_SKILLS_MAX
        }
      ),
    roles: Yup.array().min(CONSTANTS.NUM_ROLES_MIN, 'VerifyCv.addRoles'),
    totalYearsExperience: Yup.number().required('VerifyCv.EnterTotalYearsExperience'),
    workLoad: Yup.number()
      .min(
        CONSTANTS.WORKLOAD_MIN,
        t('VerifyCv.MinWorkloadTxt', { value: CONSTANTS.WORKLOAD_MIN })
      )
      .max(
        CONSTANTS.WORKLOAD_MAX,
        t('VerifyCv.MaxWorkloadTxt', { value: CONSTANTS.WORKLOAD_MAX })
      )
      .required('VerifyCv.EnterWorkload'),
    remoteDaysPerWeek: Yup.number()
      .min(CONSTANTS.WORK_FROM_HOME_DAYS_MIN)
      .max(
        CONSTANTS.WORK_FROM_HOME_DAYS_MAX,
        t('VerifyCv.enterWorkFromHomeRange', { value: CONSTANTS.WORK_FROM_HOME_DAYS_MAX })
      )
      .required('VerifyCv.EnterWorkFromHome'),
    availableFrom: Yup.date()
      .required('VerifyCv.EnterAvailableFrom')
      .typeError('general.errors.invalidDate')
      .max(CONSTANTS.AVAILABILITY_DATE_MAX, 'general.errors.invalidDate'),
    hourlyRate: Yup.object().shape({
      price: Yup.number().required('general.errors.hourlyRate.price'),
    }),
    education: Yup.lazy((values: Educations) => {
      if (values.length > 1) {
        return Yup.array().of(
          Yup.object().shape({
            organization: Yup.string().required('VerifyCv.EnterSchool'),
            accreditation: Yup.object().shape({
              education: Yup.string().notRequired()
            }),
            dates: Yup.object().shape({
              years: Yup.string().test({
                name: 'years',
                message: 'general.errors.dates.years',
                test: testYears
              })
                .required('VerifyCv.EnterYears')
            })
          })
        )
      } else {
        return Yup.mixed().notRequired();
      }
    }),
    languages: Yup.array().min(CONSTANTS.NUM_LANGUAGES_MIN, 'VerifyCv.EnterLanguage')
  })

  return (
    <Container
      maxWidth="md"
      sx={{
        px: { xs: 1, sm: 3 },
        my: 4,
      }}
    >
      <Formik
        validateOnBlur={validateOnBlur}
        validateOnChange={validateOnChange}
        validationSchema={validationSchema}
        // @ts-ignore
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const { handleSubmit, isValid, isSubmitting } = formikProps
          return (
            <section>
              <ScrollToError />
              <Typography variant="h3" gutterBottom sx={{ ml: 0.5 }}>
                {isUserRoleConsultant ? t('VerifyCv.EditCv') : t('VerifyCv.EditTheConsultantCv')}
              </Typography>
              <Typography component="p" variant="body" color="text.secondary" gutterBottom sx={{ ml: 0.5 }}>
                {t('VerifyCv.EditCvDescription')}
              </Typography>
              <Box
                sx={(theme) => ({
                  pointerEvents: isSubmitting ? 'none' : 'auto',
                  bgcolor: 'common.white',
                  mt: 4,
                  borderRadius: theme.shape.rounded,
                  p: { xs: 1, sm: 4 }
                })}
              >
                <Form onSubmit={handleSubmit}>
                  {isUserRoleManager && <UploadAvatarField name="image" id="image" />}
                  <Stack direction="column" spacing={1.5} mt={isUserRoleManager ? 4 : 0}>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={{ xs: 2, md: 1.5 }}
                    >
                      <FastNumericFormatField
                        name="remoteDaysPerWeek"
                        id="remote-days"
                        label={t(
                          isUserRoleCompany
                            ? 'general.workFromHomeAtMost'
                            : 'consultant.workFromHomeAtLeast'
                        )}
                        fullWidth
                      />
                      <FastNumericFormatField
                        name="workLoad"
                        id="workload"
                        label={t('VerifyCv.ExpectedWorkload')}
                        fullWidth
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={{ xs: 2, md: 1.5 }}
                    >
                      <FastResponsiveDatePickerField
                        name="availableFrom"
                        id="available-from"
                        label={t('VerifyCv.AvailableFrom')}
                        fullWidth
                      />
                      <FastNumericFormatField
                        name="hourlyRate.price"
                        id="hourly-rate"
                        label={t(
                          isUserRoleCompany
                            ? 'VerifyCv.minimumHourlyRate'
                            : 'consultant.minimumHourlyRate'
                        )}
                        fullWidth
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={{ xs: 2, md: 1.5 }}
                    >
                      {isUserRoleManager &&
                        <FastTextField
                          id="name"
                          name="name.raw"
                          label={t('Common.FullName')}
                          fullWidth
                        />
                      }
                      <FastTextField
                        id="profession"
                        name="profession"
                        label={t('VerifyCv.Position')}
                        fullWidth
                      />
                    </Stack>
                    <AiEnhancedTextField
                      name="summary"
                      id="ai-description"
                      label={t('VerifyCv.MyDescription')}
                      enhanceType={EnhanceType.Resume}
                      sx={{ mb: 3.5 }}
                    />
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={{ xs: 2, md: 1.5 }}
                    >
                      <GoogleAutocompleteField
                        name="location"
                        id="location"
                        label={t('VerifyCv.Location')}
                        fullWidth
                      />
                      <FastNumericFormatField
                        name="distance"
                        id="distance"
                        label={t('VerifyCv.CommutingDistance')}
                        fullWidth
                      />
                    </Stack>
                    <EmploymentsFieldArray id="experience" />
                    <AutocompleteField
                      name="skills"
                      id="skills"
                      label={t('Common.Skills')}
                      topHelperText={t('VerifyCv.skillsTopHelperText')}
                      options={skillOptions}
                      isChipClickable
                      isPrimaryDigit
                      chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
                    />
                    <FastNumericFormatField
                      name="totalYearsExperience"
                      id="years-experience"
                      label={t('VerifyCv.TotalYearsExperience')}
                    />
                    <AutocompleteField
                      name="roles"
                      id="roles"
                      label={t('VerifyCv.roles')}
                      topHelperText={t('VerifyCv.rolesTopHelperText')}
                      options={roleOptions}
                      // @ts-ignore
                      getOptionLabel={(option) => option?.jobTitle || option?.name}
                      chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
                    />
                    <EducationFieldArray
                      id="education"
                      topHelperText={t('VerifyCv.optional')}
                    />
                    <AutocompleteField
                      name="languages"
                      id="languages"
                      label={t('VerifyCv.languages')}
                      options={languageOptions}
                      chipSx={{ maxWidth: { xs: '175px', sm: '100%' } }}
                    />
                    <Stack direction="row" spacing={1.5}>
                      <Button
                        id="save"
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={!isValid || isSubmitting}
                        onClick={manualSubmitForm(formikProps)}
                      >
                        {isEditCvPath && t('VerifyCv.SaveChanges')}
                        {isCreateCvPath && t('UploadCv.CreateCv')}
                      </Button>
                      <Button id="cancel" variant="outlined" size="large" onClick={onCancelCv}>
                        {t('VerifyCv.Cancel')}
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Box>
            </section>
          )
        }}
      </Formik>
    </Container>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as schemas from 'validation/schemas'
import { useLocation, useNavigate } from 'react-router'
import { enqueueSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import { isResponseStatusNotFound, setVerifyEmailReminder } from 'utils/utils'
import { UpdateUser } from 'api/user/queries'
import { useConsultantCvQuery } from 'api/cvs/queries'
import ScrollToError from 'components/common/ScrollToError'
import useValidateAfterFirstSubmit from 'components/ui/FormikFields/helpers/useValidateAfterFirstSubmit'
import UploadAvatarField from 'components/ui/FormikFields/UploadAvatarField'
import InputField from 'components/ui/FormikFields/InputField'
import PasswordField from 'components/ui/FormikFields/PasswordField'
import useUploadProfileCoverPhotos from 'hooks/useUploadProfileCoverPhotos'
import AlertDialog from 'components/ui/AlertDialog/AlertDialog'
import { Container } from '@mui/material'

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Common.EnterFullName').min(5, 'Registration.ShortName'),
  email: Yup.string().email('Registration.EnterValidEmail').required('Registration.EnterEmail'),
  newPassword: schemas.notRequiredPassword(),
  confirmNewPassword: Yup.string().when('newPassword', {
    is: (newPassword: string) => newPassword,
    then: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Registration.PassMustMatch')
      .required('Common.ConfirmNewPassword'),
  }),
})

type ConsultantSettingsFormValues = {
  image: {
    url: string
    file: File | null
  }
  fullName: string
  email: string
  newPassword: string
  confirmNewPassword: string
}

const ConsultantSettings = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false)

  const {
    validateOnChange,
    validateOnBlur,
    onSubmit: manualSubmitForm,
  } = useValidateAfterFirstSubmit()

  const auth = useAuthContext()
  const { data: userData, isLoading, isError: isUserError } = auth.user

  const userCvs = useConsultantCvQuery()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const isUserCvsNotFound = isResponseStatusNotFound(userCvs.error) || !userCvs?.data?.body

  const initialValues = useMemo(
    () => ({
      image: {
        url: (userData && userData.profile_photo) ?? '',
        file: null,
      },
      fullName: userData?.name || '',
      email: userData?.email || '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [userData]
  )

  const onDeleteProfile = () => {
    setIsAlertDialogOpen(true)
  }

  const onAlertDialogClose = () => {
    setIsAlertDialogOpen(false)
  }

  const onAlertDialogOk = () => {
    auth.deleteConsultantProfile(() => {
      enqueueSnackbar(t('general.profileDeletedSuccessMsg'), { variant: 'success' })
    })
  }

  const onSubmit = async (values: ConsultantSettingsFormValues) => {
    const { image, fullName, email, newPassword } = values

    const newDataUser: UpdateUser = {
      name: fullName,
      email,
    }

    if (newPassword) {
      newDataUser.password = newPassword
    }

    try {
      //Photo processing - uploading to amazon / deleting from amazon / or returning the old value
      const profilePhotoData = await uploadPhoto(image, userData?.profile_photo)
      newDataUser.profile_photo = profilePhotoData.photo

      auth.updateConsultant(newDataUser, () => {
        enqueueSnackbar(t('general.changesAreSaved'), { variant: 'success' })
        auth.renewToken()

        if (userData?.email !== email) {
          setVerifyEmailReminder(true)
        }
        navigate(RoutePathNames.consultant.home);
      })
    } catch (err) {
      // console.log('err', err)
    }
  }

  const onCancel = () => {
    navigate(RoutePathNames.consultant.home)
  }

  const onEditCvs = () => {
    navigate(RoutePathNames.consultant.editCvs)
  }

  const onUploadCvs = () => {
    navigate(RoutePathNames.consultant.uploadCvs, {
      state: {
        pathname: location.pathname,
      },
    })
  }

  if (isLoading || isUserError) {
    return (
      <Box sx={{ p: '80px', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        px: { xs: 1, sm: 3 },
        my: 4
      }}
    >
      <AlertDialog
        open={isAlertDialogOpen}
        title="Are you sure you want to delete your profile?"
        onClose={onAlertDialogClose}
        onOk={onAlertDialogOk}
        loading={auth.deleteConsultantProfileMutation.isLoading}
      />
      <Formik
        validateOnBlur={validateOnChange}
        validateOnChange={validateOnBlur}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        // @ts-ignore
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const { handleSubmit, isValid, isSubmitting } = formikProps
          return (
            <section>
              <ScrollToError />
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb="4px">
                <Typography variant="h2">{t('Common.ProfileSettings')}</Typography>
              </Stack>
              <Box
                sx={(theme) => ({
                  bgcolor: 'common.white',
                  mt: 4,
                  borderRadius: theme.shape.rounded,
                  p: { xs: 1, sm: 4 }
                })}
              >
                <Form onSubmit={handleSubmit}>
                  <UploadAvatarField name="image" id="image" />
                  <Stack direction="column" spacing={1.5} mt={4} mb={2}>
                    <InputField name="fullName" id="name" label={t('Common.FullName')} fullWidth />
                    <InputField name="email" id="email" label={t('Common.Email')} fullWidth />
                    <PasswordField
                      name="newPassword"
                      label={t('Common.NewPassword')}
                      fullWidth
                    />
                    <PasswordField
                      name="confirmNewPassword"
                      label={t('Common.ConfirmNewPassword')}
                      fullWidth
                    />
                    <div>
                      <Typography variant="h5">{t('Common.YourResume')}</Typography>
                      <Box
                        id="resume"
                        sx={(theme) => ({
                          mt: 1.5,
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: `1px dashed ${theme.palette.secondary[40]}`,
                          borderRadius: theme.shape.rounded,
                          maxWidth: 420,
                          minHeight: 111
                        })}
                      >
                        <Typography variant="body" color="grey.400" fontWeight="fontWeightBold">
                          {userCvs?.data?.resume_file_name || t('general.noResume')}
                        </Typography>
                      </Box>
                    </div>
                  </Stack>
                  <Button
                    id="edit-or-upload"
                    variant="contained"
                    color="secondary"
                    onClick={isUserCvsNotFound ? onUploadCvs : onEditCvs}
                    startIcon={<PictureAsPdfIcon />}
                  >
                    {isUserCvsNotFound ? t('UploadCv.UploadCv') : t('Common.EditCV')}
                  </Button>
                  <Stack
                    mt={6}
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    spacing={1.5}
                  >
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 2, sm: 1.5 }}
                    >
                      <Button
                        id="save"
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={!isValid || isSubmitting}
                        onClick={manualSubmitForm(formikProps)}
                      >
                        {t('Common.SaveChanges')}
                      </Button>
                      <Button id="cancel" variant="outlined" size="large" onClick={onCancel}>
                        {t('Common.Cancel')}
                      </Button>
                    </Stack>
                    <Button id="delete" color="error" size="large" onClick={onDeleteProfile}>
                      {t('general.deleteProfile')}
                    </Button>
                  </Stack>
                </Form>
              </Box>
            </section>
          )
        }}
      </Formik>
    </Container>
  )
}

export default ConsultantSettings

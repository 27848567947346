import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Profile from "components/common/Profile";
import { UserCvsBody, usePublicConsultantCvByIdQuery } from "api/cvs/queries";
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from "react-i18next";
import { ApplicantStatus } from "./ApplicationHandler";
import { DropResult } from "react-beautiful-dnd";

type ApplicantDetailsDialogProps = {
  open: boolean;
  onClose: () => void;
  consultantId: number | string;
  sourceId: keyof ApplicantStatus;
  sourceIndex: number;
  onDragEnd: (result: DropResult) => void;
  assignmentIsClosed: boolean;
}

const ApplicantDetailsDialog: React.FC<ApplicantDetailsDialogProps> = ({
  open,
  onClose,
  consultantId,
  sourceId,
  sourceIndex,
  onDragEnd,
  assignmentIsClosed
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const xsScreenSize = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = usePublicConsultantCvByIdQuery(consultantId)
  const body = data?.body || {} as UserCvsBody

  const onClick = (destinationId: keyof ApplicantStatus) => {
    const result = {
      destination: {
        droppableId: destinationId,
        index: 0
      },
      source: {
        droppableId: sourceId,
        index: sourceIndex
      }
    } as DropResult
    onDragEnd(result);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xl'
      fullWidth
      fullScreen={xsScreenSize}
    >
      <IconButton
        onClick={onClose}
        sx={{
          p: '4px',
          top: 16,
          right: 16,
          position: 'absolute',
        }}
      >
        <CloseIcon id="close" sx={{ fontSize: '18px' }} />
      </IconButton>
      <DialogTitle>
        <Typography>
          {t('jobListings.applicantDetails')}
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Profile {...body} />
      </DialogContent>
      <DialogActions>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          sx={{ width: { xs: '100%', sm: 'auto' } }}
        >
          <Button onClick={onClose} color="secondary">
            {t('general.cancel')}
          </Button>
          <Button
            onClick={() => onClick('review')}
            color="warning"
            variant="contained"
            disabled={sourceId === 'review' || assignmentIsClosed}
          >
            {t('Review')}
          </Button>
          <Button
            onClick={() => onClick('interview')}
            color="secondary"
            variant="contained"
            disabled={sourceId === 'interview' || assignmentIsClosed}
          >
            {t('Interview')}
          </Button>
          <Button
            onClick={() => onClick('contract')}
            color="success"
            variant="contained"
            disabled={sourceId === 'contract' || assignmentIsClosed}
          >
            {t('Contract')}
          </Button>
          <Button
            onClick={() => onClick('reject')}
            color="error"
            variant="contained"
            disabled={sourceId === 'reject' || assignmentIsClosed}
          >
            {t('Reject')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default ApplicantDetailsDialog;

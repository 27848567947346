import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Divider, IconButton, Popover, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationHandler, { Notification } from "./NotificationHandler";
// import SettingsIcon from '@mui/icons-material/Settings';

type NotificationsProps = {
  notifications: Notification[]
}

const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUnreadCount(notifications.filter(n => !n.read).length);
  }, [notifications])

  const renderNotifications = () => {
    if (notifications.length === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="120px"
          minWidth={{ xs: 300, sm: 400 }}
        >
          <Typography >{t('notification.noNotifications')}</Typography>
        </Box>
      )
    } else {
      return (
        <NotificationHandler
          notifications={notifications}
          reduceCount={() => setUnreadCount(prev => prev-1)}
        />
      )
    }
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleOpen}>
        <Badge badgeContent={unreadCount > 9 ? "9+" : unreadCount} color="error">
          {anchorEl ? <NotificationsIcon /> : <NotificationsOutlinedIcon />}
        </Badge>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            paddingY: 0.5,
            height: 48
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              sx={{
                minWidth: '8px',
                minHeight: '8px',
                backgroundColor: unreadCount > 0 ? "blue" : "transparent",
                borderRadius: "50%",
                marginRight: 2
              }}
            />
            <Typography>{t('notification.notifications')}</Typography>
          </Stack>
          {/* Left this as a comment in case a settings button is needed in the future */}
          {/* <IconButton>
            <SettingsIcon/>
          </IconButton> */}
        </Stack>
        <Divider/>
        {renderNotifications()}
      </Popover>
    </>
  );
};

export default Notifications;

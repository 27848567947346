import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik'
import * as yup from 'yup'
import { enqueueSnackbar } from 'notistack'
// import RoutePathNames from 'routes/routePathNames';
import { UpdateCompany, useCompanyByUserIdQuery } from 'api/company/queries'
import { useAuthContext } from 'context/auth-context'
import { useUpdateCompanyMutation } from 'api/company/mutations'
import useUploadProfileCoverPhotos from 'hooks/useUploadProfileCoverPhotos'
import SettingsForm, { validSchema, initValues } from 'components/common/forms/SettingsForm'
import { useNavigate } from 'react-router-dom'
import RoutePathNames from 'routes/routePathNames'
import { setVerifyEmailReminder } from 'utils/utils'

const validationSchema = validSchema.shape({
  company_name: yup
    .string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCompanyName'),
  company_description: yup
    .string()
    .min(5, 'Common.CharsMinimum5')
    .required('CompanySettings.EnterAboutCompany'),
  name: yup.string().required('Common.EnterFullName').min(5, 'Registration.ShortName'),
})

const initialValues = {
  ...initValues,
  name: '',
  company_name: '',
  company_description: '',
}

type FormValues = typeof initialValues

export default function CompanySettings() {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const {
    user: { data: userData },
    renewToken,
    refetchCompany
  } = useAuthContext()

  const { data: companyData } = useCompanyByUserIdQuery()

  const company = useUpdateCompanyMutation()

  const { uploadPhoto } = useUploadProfileCoverPhotos()

  const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { confirmNewPassword, profile_photo, cover_picture, newPassword, ...restValues } = values

    const newCompanyData: UpdateCompany = {
      ...restValues,
    }

    if (newPassword) {
      newCompanyData.password = newPassword
    }

    try {
      //Photo processing logo - uploading to amazon / deleting from amazon / or returning the old value
      const profilePhotoData = await uploadPhoto(profile_photo, companyData?.profile_photo)
      newCompanyData.profile_photo = profilePhotoData.photo
      //Photo processing cover - uploading to amazon / deleting from amazon / or returning the old value
      const coverPictureData = await uploadPhoto(cover_picture, companyData?.cover_picture)
      newCompanyData.cover_picture = coverPictureData.photo

      await company.mutateAsync(newCompanyData, {
        onSuccess: () => {
          setSubmitting(false)
          enqueueSnackbar(t('general.changesAreSaved'), { variant: 'success' })
          renewToken()
          refetchCompany()

          if (userData?.email !== values.email) {
            setVerifyEmailReminder(true)
          }
          navigate(RoutePathNames.company.home)
        },
      })
    } catch (err) { }
  }

  const initialValues = useMemo(
    () => ({
      // isNewPassword: '',
      company_name: (companyData && companyData.company_name) ?? '',
      org_number: (companyData && companyData.org_number) ?? '',
      address_line: (companyData && companyData.address_line) ?? '',
      address_line_2: (companyData && companyData.address_line_2) ?? '',
      city: (companyData && companyData.city) ?? '',
      zip_code: (companyData && companyData.zip_code) ?? '',
      country: (companyData && companyData.country) ?? '',
      company_description: (companyData && companyData.company_description) ?? '',
      profile_photo: {
        url: (companyData && companyData.profile_photo) ?? '',
        file: null,
      },
      cover_picture: {
        url: (companyData && companyData.cover_picture) ?? '',
        file: null,
      },
      name: (userData && userData?.name) ?? '',
      email: (userData && userData?.email) ?? '',
      newPassword: '',
      confirmNewPassword: '',
    }),
    [companyData, userData]
  )

  return (
    <SettingsForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}

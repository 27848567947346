import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Box, Typography, CircularProgress, PaginationItem, Stack } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { getPaginationCount, getPaginationOffset } from 'utils/utils'
import { useSearchConsultantByManagerMutation } from 'api/manager/mutations'
import Filter, { FormValues, SearchType } from 'components/Filter'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { StyledPagination } from 'components/common/StyledPagination'
import { CvSearchParams } from 'api/cvs/mutations'
import * as DEFAULTS from '../../../api/constants/filterDefaults'
import { useAuthContext } from 'context/auth-context'

const pageLimit = 12

const getSearchBody = (
  values: FormValues
): CvSearchParams => {
  const searchBody: CvSearchParams = {}

  const parsedSkills = values.skills.map((skill) => skill?.name);
  if (parsedSkills.length !== 0) {
    searchBody.skills = parsedSkills;
  }

  const parsedRoles = values.roles.map((role) => role?.name);
  if (parsedRoles.length !== 0) {
    searchBody.roles = parsedRoles;
  }

  const parsedLanguages = values.languages.map((language) => language?.name);
  if (parsedLanguages.length !== 0) {
    searchBody.languages = parsedLanguages;
  }

  if (values.hourlyRate !== "" && values.hourlyRate !== DEFAULTS.HOURLY_RATE) {
    searchBody.maxHourlyRate = values.hourlyRate;
  }

  if (
    values.workLoad[0] !== DEFAULTS.WORKLOAD_MIN ||
    values.workLoad[1] !== DEFAULTS.WORKLOAD_MAX
  ) {
    searchBody.workLoad = values.workLoad;
  }

  if (values.remoteDaysPerWeek[0] !== DEFAULTS.REMOTE_DAYS_PER_WEEK_MAX) {
    searchBody.maxRemoteDaysPerWeek = values.remoteDaysPerWeek[0];
  }

  if (values.availableFrom !== DEFAULTS.AVAILABLE_FROM) {
    searchBody.availableFrom = values.availableFrom.getTime()
  }

  if (values.location?.lat && values.location?.lng) {
    searchBody.coordinates = {
      lat: values.location.lat,
      long: values.location.lng
    }
  }

  return searchBody;
}

export default function ManagerConsultantListings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedPage = Number.parseInt(searchParams.get('page') || '1', 10);
  const [page, setPage] = useState(isNaN(parsedPage) ? 1 : parsedPage);

  const { isLoading, data: { data: searchData, paginationParams } = {}, mutateAsync } = useSearchConsultantByManagerMutation()
  const total = paginationParams?.total || 0;
  const paginationCount = getPaginationCount(total, pageLimit);

  const { isUserRoleAdmin, user } = useAuthContext();

  const { t } = useTranslation()

  const performSearch = async (values: FormValues, currentPage: number) => {
    setPage(currentPage)
    const offset = getPaginationOffset(currentPage, pageLimit);

    const onlyManagerConsultants = values.onlyManagerConsultants
    const body: CvSearchParams = getSearchBody(values);
    await mutateAsync(
      { body, offset, limit: pageLimit, onlyManagerConsultants },
      { onSuccess: () => { } }
    )
  }

  // triggers a search through the useEffect in Filter
  const onPaginationChange = (newPage: number) => {
    setSearchParams(() => {
      const params = new URLSearchParams(searchParams)
      params.set('page', newPage.toString())
      return params
    })
  };

  const renderConsultantList = () => {
    if (isLoading && !searchData) {
      return (
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      )
    } else if (searchData && searchData?.length > 0) {
      return (
        <>
          <StyledPagination
            id="upper-pagination"
            page={page}
            count={paginationCount}
            shape="rounded"
            color="secondary"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                component="button"
                onClick={() => onPaginationChange(item.page || 1)}
              />
            )}
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-start"
            flexWrap="wrap"
            gap={2}
            sx={{ width: '100%' }}
          >
            {searchData.map((item: any) => (
              <ProfileCard
                key={item.assignment_id}
                profile={item}
                showEditButton
                disableEditButton={isUserRoleAdmin && item.body.manager_email !== user.data?.email}
              />
            ))}
          </Stack>
          <StyledPagination
            id="lower-pagination"
            page={page}
            count={paginationCount}
            shape="rounded"
            color="secondary"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                component="button"
                onClick={() => onPaginationChange(item.page || 1)}
              />
            )}
          />
        </>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '35%',
          }}
        >
          <FindInPageIcon
            sx={{ fontSize: '100px', color: 'rgba(16, 18, 45, 0.6)' }}
          ></FindInPageIcon>
          <Typography variant="h3">{t('jobListings.notFoundTitle')}</Typography>
          <Typography fontSize={'18px'} color={'rgba(16, 18, 45, 0.6)'}>
            {t('jobListings.notFoundDescription')}
          </Typography>
        </div>
      )
    }
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: 2,
        px: { xs: 1, sm: 2 }
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems={{ xs: 'stretch', md: 'flex-start' }}
        spacing={2}
      >
        <Box
          sx={(theme) => ({
            minWidth: { xs: 260, md: 300 },
            width: { xs: '100%', md: 300 },
            px: theme.spacing(2),
            py: theme.spacing(4),
            bgcolor: 'common.white',
            borderRadius: theme.shape.rounded,
          })}
        >
          <Filter
            performSearch={performSearch}
            searchType={SearchType.Consultant}
          />
        </Box>
        <Box
          maxWidth="1152px"
          width="100%"
        >
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
          >
            {renderConsultantList()}
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

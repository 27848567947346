import React from 'react'
import { useMatch, Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import RoutePathNames from 'routes/routePathNames'
import DefaultFallback from '../components/common/DefaultFallback'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import { Stack } from '@mui/material'

export default function MainLayout() {
  const isUploadCvRoute = useMatch(RoutePathNames.consultant.uploadCvs)

  return (
    <Stack
      justifyContent="space-between"
      sx={{ minHeight: '100vh' }}
    >
      <Stack>
        <Header />
        <ErrorBoundary FallbackComponent={DefaultFallback}>
          <Outlet />
        </ErrorBoundary>
      </Stack>
      {!isUploadCvRoute && <Footer />}
    </Stack>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Container, Skeleton, Stack, Typography } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import { useManagerByUserIdQuery } from 'api/manager/queries'
import ChipWithIcon from 'components/ui/ChipWithIcon/ChipWithIcon'
import ManagerConsultantListings from 'components/manager/ManagerConsultantListings'
import pencilIcon from 'static/svg/pencil.svg'
import VerifyEmailDialog from 'components/common/VerifyEmailDialog'
import { useAuthContext } from 'context/auth-context'
import CoverImage from 'components/common/Cover'

export default function ManagerLanding() {
  const managerByUserId = useManagerByUserIdQuery()
  const { data: managerData } = managerByUserId

  const { user, isUserEmailVerified } = useAuthContext()
  const email = user?.data?.email || ''

  const {
    profile_photo,
    cover_picture,
    manager_name = '',
    manager_description = '',
    city = '',
    country = '',
  } = managerData || {}

  const { t } = useTranslation()

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{ pb: 10 }}
    >
      <CoverImage src={cover_picture} />
      <Container>
        <Box mb={4}>
          <Avatar
            alt="logo"
            src={profile_photo ?? ''}
            sx={{
              width: { xs: 100, sm: 150 },
              height: { xs: 100, sm: 150 },
              borderRadius: 4,
              mt: { xs: '-50px', sm: '-75px' }
            }}
          />
        </Box>

        <Typography variant="h2" mb={2}>
          {manager_name ? manager_name : <Skeleton width={200} />}
        </Typography>

        <div>
          <ChipWithIcon label={`${city}, ${country}`} img="location" imgType="svg" />
        </div>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1.5, sm: 2 }} my={4}>
          <Button
            component={Link}
            to={RoutePathNames.manager.settings}
            id="edit"
            variant="contained"
            color="secondary"
            size="large"
            startIcon={
              <Avatar
                alt=""
                src={pencilIcon}
                sx={{ width: 16, height: 16, borderRadius: '0', mr: '1px' }}
              />
            }
          >
            {t('ProfileCommon.EditProfile')}
          </Button>

          <Button
            component={Link}
            to={RoutePathNames.manager.consultant.create}
            id="create-consultant"
            variant="outlined"
            color="primary"
            size="large"
            startIcon="+"
          >
            {t('general.addConsultant')}
          </Button>
          {!isUserEmailVerified && (
            <VerifyEmailDialog
              changeEmailLink={RoutePathNames.manager.settings}
              email={email}
            />
          )}
        </Stack>

        <Box mb="60px">
          <Typography
            variant="body"
            sx={{ display: 'inline-block' }}
            color="primary.60"
            maxWidth={626}
          >
            {manager_description ? manager_description : null}
          </Typography>
        </Box>

        <ManagerConsultantListings />
      </Container>
    </Container>
  )
}

import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FastFieldProps, useField, FastField } from 'formik'
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Chip,
  Typography,
  Box,
  Stack,
  createFilterOptions,
  SxProps,
  Theme
} from '@mui/material'
import { checkIsSkillPrimary } from 'utils/utils'

type AutocompleteComponentProps<T> = Omit<FastFieldProps, 'form'> &
  Omit<AutocompleteFieldProps<T>, 'name'> & {
    onChange: (newValue: unknown) => void
    isPrimaryDigit?: boolean
  }

const OPTIONS_LIMIT = 10
// @ts-ignore
const toggleIsPrimary = (
  { isPrimary }: { isPrimary: number | boolean },
  isPrimaryDigit: boolean
) => {
  if (isPrimaryDigit) {
    if (isPrimary === 5 || isPrimary === true) {
      return 1
    } else if (isPrimary === 1 || isPrimary === false) {
      return 5
    }
  } else {
    return !isPrimary
  }
}
// @ts-ignore
const setIsPrimary = ({ isPrimary }: { isPrimary: number | boolean }, isPrimaryDigit: boolean) => {
  if (isPrimaryDigit) {
    if (isPrimary === 5 || isPrimary === true) {
      return 5
    } else if (isPrimary === 1 || isPrimary === false) {
      return 1
    }
  } else {
    return isPrimary
  }
}

function AutocompleteComponent<T>({
  id,
  field,
  meta,
  options = [],
  isChipClickable = false,
  // @ts-ignore
  getOptionLabel = (option: T) => option?.name ?? '',
  // label = '',
  textFieldLabel = '',
  // topHelperText = '',
  placeholder = '',
  chipSx = { maxWidth: '100%' },
  // multiple = true,
  disableClearable = false,
  // filterSelectedOptions = true,
  // fullWidth = false,
  // size,
  onChange,
  isPrimaryDigit = false,
}: // ...restProps
  AutocompleteComponentProps<T>) {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const error = meta.touched && meta.error

  const isPrimary = (option: T) => {
    // @ts-ignore
    return field.value.some(
      // @ts-ignore
      (opt: T) =>
        // @ts-ignore
        getOptionLabel(opt) === getOptionLabel(option) && checkIsSkillPrimary(opt, isPrimaryDigit)
    )
  }

  const setPrimary = (options: T[]) => {
    return options.map((opt: T) => ({
      ...opt,
      isPrimary: setIsPrimary({ isPrimary: isPrimary(opt) }, isPrimaryDigit),
    }))
  }

  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  })

  useEffect(() => {
    if (!open && inputValue) {
      setOpen(true)
    } else if (open && !inputValue) {
      setOpen(false)
    }
  }, [open, inputValue])

  const { t } = useTranslation()

  return (
    <MuiAutocomplete
      id={id}
      open={open}
      multiple={true}
      disableClearable={disableClearable}
      filterSelectedOptions={true}
      // includeInputInList={true}
      // autoComplete={true}
      value={field.value}
      options={options}
      filterOptions={filterOptions}
      // filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => getOptionLabel(option) === getOptionLabel(value)}
      onBlur={field.onBlur}
      onChange={(e, value) => {
        // @ts-ignore
        onChange(isChipClickable ? setPrimary(value) : value)
      }}
      // @ts-ignore
      renderTags={(value, getTagProps, ownerState) => {
        return (
          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
            {value.map((v, index) => {
              const tagProps = getTagProps({ index })
              const label = getOptionLabel(v)
              return (
                <Chip
                  key={label + index}
                  sx={chipSx}
                  label={label}
                  clickable={isChipClickable}
                  variant={isChipClickable ? (isPrimary(v) ? 'filled' : 'outlined') : 'filled'}
                  color={isChipClickable ? (isPrimary(v) ? 'secondary' : 'default') : 'secondary'}
                  onClick={
                    isChipClickable
                      ? () => {
                        // @ts-ignore
                        const newValue = field.value.map((val: any) =>
                          getOptionLabel(val) === label
                            ? { ...val, isPrimary: toggleIsPrimary(val, isPrimaryDigit) }
                            : val
                        )
                        onChange(newValue)
                      }
                      : // @ts-ignore
                      () => { }
                  }
                  onDelete={(event) => {
                    tagProps.onDelete(event)
                    // @ts-ignore
                    const newValue = field.value.filter((val: any) => getOptionLabel(val) !== label)
                    onChange(newValue)
                  }}
                />
              )
            })}
          </Stack>
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={textFieldLabel}
            placeholder={field.value && field.value.length > 0 ? '' : placeholder}
            error={!!error}
            helperText={error ? t(`${error}`) : ' '}
          />
        )
      }}
    />
  )
}

type AutocompleteFieldProps<T> = {
  name: string
  options: T[]
  isChipClickable?: boolean
  getOptionLabel?: (option: T) => string
  // size?: 'small' | 'medium';
  label?: string
  textFieldLabel?: string
  topHelperText?: string
  placeholder?: string
  chipSx?: SxProps<Theme>
  isPrimaryDigit?: boolean
  // multiple?: boolean;
  disableClearable?: boolean
  // filterSelectedOptions?: boolean;
  // fullWidth?: boolean;
  id: string;
}

export default function AutocompleteField<T>({
  name,
  label = '',
  topHelperText = '',
  isPrimaryDigit = false,
  id,
  ...restProps
}: AutocompleteFieldProps<T>) {
  const [field, meta, helpers] = useField({ name })

  const onChange = useCallback(
    // @ts-ignore
    (newValue: any) => {
      helpers.setValue(newValue)
    },
    [helpers]
  )

  return (
    <>
      <AutocompleteFieldLabel label={label} topHelperText={topHelperText} />
      <AutocompleteComponent
        field={field}
        meta={meta}
        onChange={onChange}
        isPrimaryDigit={isPrimaryDigit}
        id={id}
        {...restProps}
      />
    </>
  )
}

type FastAutocompleteFieldProps<T> = AutocompleteFieldProps<T>

export function FastAutocompleteField<T>({
  name,
  label = '',
  topHelperText = '',
  isPrimaryDigit = false,
  id,
  ...restProps
}: FastAutocompleteFieldProps<T>) {
  const onChange = useCallback(
    ({ form, field }: FastFieldProps) =>
      // @ts-ignore
      (newValue: any) => {
        form.setFieldValue(field.name, newValue)
      },
    []
  )

  return (
    <>
      <AutocompleteFieldLabel label={label} topHelperText={topHelperText} />
      <FastField name={name}>
        {(props: FastFieldProps) => (
          <AutocompleteComponent
            onChange={onChange(props)}
            isPrimaryDigit={isPrimaryDigit}
            id={id}
            {...props}
            {...restProps}
          />
        )}
      </FastField>
    </>
  )
}

type AutocompleteFieldLabelProps<T> = Pick<AutocompleteFieldProps<T>, 'label' | 'topHelperText'>

function AutocompleteFieldLabel<T>({ label, topHelperText }: AutocompleteFieldLabelProps<T>) {
  return label || topHelperText ? (
    <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', columnGap: 1 }}>
      {label && <Typography variant="h5">{label}</Typography>}
      {topHelperText && (
        <Typography variant="body-sm" color="primary.60">
          {topHelperText}
        </Typography>
      )}
    </Box>
  ) : null
}

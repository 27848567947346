import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Form, Formik, FormikConfig } from 'formik'
import * as Yup from 'yup'
import * as schemas from 'validation/schemas'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import RoutePathNames from 'routes/routePathNames'
import InputField from 'components/ui/FormikFields/InputField'
import { useAuthContext } from 'context/auth-context'
import UploadLogoField from 'components/ui/FormikFields/UploadLogoField/UploadLogoField'
import UploadCoverField from 'components/ui/FormikFields/UploadCoverField/UploadCoverField'
import ScrollToError from '../ScrollToError'
import InputPasswordField from '../../ui/FormikFields/PasswordField'

export const validSchema = Yup.object().shape({
  profile_photo: Yup.object().shape({
    url: Yup.string().required('Registration.EnterProfilePicture')
  }),
  cover_picture: Yup.object().shape({
    url: Yup.string().required('Registration.EnterCoverPicture')
  }),
  org_number: schemas.requiredOrgNumber(),
  address_line: Yup.string().min(2, 'Common.CharsMinimum2').required('Registration.EnterAddress'),
  address_line_2: Yup.string(),
  city: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCity')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
  zip_code: Yup.string().min(2, 'Common.CharsMinimum2').required('Registration.EnterZipCode'),
  country: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCountry')
    .matches(/^(.(?!([0-9])))+$/, 'Common.NotNumber'),
  email: Yup.string().email('Registration.EnterValidEmail').required('Registration.EnterEmail'),
  newPassword: schemas.notRequiredPassword(),
  confirmNewPassword: Yup.string().when('newPassword', {
    is: (newPassword: string) => newPassword,
    then: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Registration.PassMustMatch')
      .required('Common.ConfirmNewPassword'),
  }),
})

type SettingsFormInitialValues = {
  profile_photo: { url: string; file: File | null }
  cover_picture: { url: string; file: File | null }
  org_number: string
  address_line: string
  address_line_2: string
  city: string
  zip_code: string
  country: string
  email: string
  newPassword: string
  confirmNewPassword: string
}

export const initValues: SettingsFormInitialValues = {
  profile_photo: { url: '', file: null },
  cover_picture: { url: '', file: null },
  org_number: '',
  address_line: '',
  address_line_2: '',
  city: '',
  zip_code: '',
  country: '',
  email: '',
  newPassword: '',
  confirmNewPassword: '',
}

export default function SettingsForm<T extends typeof initValues>({
  validationSchema = validSchema,
  initialValues = initValues as T,
  onSubmit,
}: FormikConfig<T>) {
  const { isUserRoleManager, isUserRoleCompany } = useAuthContext()

  const navigate = useNavigate()

  const onCancel = () => {
    if (isUserRoleManager) {
      navigate(RoutePathNames.manager.home)
    } else {
      navigate(RoutePathNames.company.home)
    }
  }

  const { t } = useTranslation()

  return (
    <Container
      maxWidth="md"
      sx={{ py: 4, px: { xs: 1, sm: 3 }}}
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <ScrollToError />
            <Typography variant="h2" sx={{ pb: 4 }}>{t('CompanySettings.PageTitle')}</Typography>

            <Box
              sx={(theme) => ({
                mx: 'auto',
                bgcolor: theme.palette.common.white,
                borderRadius: theme.shape.rounded,
                p: { xs: 1, sm: 4},
              })}
            >
              <Stack direction="column" spacing={1.5}>
                <Typography variant="h4" sx={{ pb: '11px' }}>
                  {t('CompanySettings.CompanyInfo')}
                </Typography>

                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  justifyContent="space-between"
                  spacing={2}
                >
                  {isUserRoleManager ? (
                    <InputField name="manager_name" id="manager-name" label={t('Common.FullName')} fullWidth />
                  ) : (
                    <InputField name="company_name" id="company-name" label={t('Common.CompanyName')} fullWidth />
                  )}
                  <InputField name="org_number" id="org-number" label={t('Common.OrgNumber')} fullWidth />
                </Stack>

                <InputField name="address_line" id="address-1" label={t('Common.Address1')} fullWidth />

                <InputField name="address_line_2" id="address-2" label={t('Common.Address2')} fullWidth />

                <Stack
                  direction={{ sm: 'row', xs: 'column' }}
                  justifyContent="space-between"
                  spacing={2}
                >
                  <InputField name="city" id="city" label={t('Common.City')} fullWidth />

                  <InputField name="zip_code" id="zip-code" label={t('Common.ZipCode')} fullWidth />
                </Stack>

                <InputField name="country" id="country" label={t('Common.Country')} fullWidth />

                <Typography variant="h5" sx={{ pb: '4px' }}>
                  {t('CompanySettings.LogoTitle')}
                </Typography>

                <UploadLogoField name="profile_photo" id="logo" />

                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                  sx={{ pb: '4px' }}
                  spacing={1}
                >
                  <Typography variant="h5">{t('CompanySettings.CoverTitle')}</Typography>
                  <Typography variant="body-sm" color="primary.60">
                    {t('Common.RecommendedSize', { size: '1920x540 (32:9)' })}
                  </Typography>
                </Stack>

                <UploadCoverField name="cover_picture" id="cover" />

                {isUserRoleManager ? (
                  <InputField
                    name="manager_description"
                    id="manager-description"
                    multiline
                    sx={{ mb: '25px' }}
                    label={t('manager.about')}
                    fullWidth
                  />
                ) : (
                  <InputField
                    name="company_description"
                    id="company-description"
                    multiline
                    sx={{ mb: '25px' }}
                    label={t('CompanySettings.AboutCompany')}
                    fullWidth
                  />
                )}

                <Typography variant="h4" sx={{ pb: '11px' }}>
                  {t('CompanySettings.PersonalInfo')}
                </Typography>

                {isUserRoleCompany && (
                  <InputField name="name" id="name" label={t('Common.FullName')} fullWidth />
                )}

                <InputField name="email" id="email" label={t('Common.Email')} fullWidth />

                <InputPasswordField
                  name="newPassword"
                  label={t('Common.NewPassword')}
                  fullWidth
                />

                <InputPasswordField
                  name="confirmNewPassword"
                  label={t('Common.ConfirmNewPassword')}
                  fullWidth
                />

                <Stack direction={{ sm: 'row', xs: 'column' }} sx={{ mt: '9px' }} spacing={2}>
                  <Button
                    id="save"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ p: '10px 40px' }}
                    disabled={isSubmitting}
                  >
                    {t('Common.SaveChanges')}
                  </Button>

                  <Button
                    id="cancel"
                    onClick={onCancel}
                    variant="outlined"
                    size="large"
                    sx={{ p: '10px 40px' }}
                  >
                    {t('Common.Cancel')}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

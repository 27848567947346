
export default class DateBuilder extends Date {
  /**
   * Sets the hour value in the Date object using local time and returns the modified DateBuilder instance for chaining.
   *
   * @param hours - A numeric value equal to the hour value to set.
   * @param min - Optional. A numeric value equal to the minutes value to set.
   * @param sec - Optional. A numeric value equal to the seconds value to set.
   * @param ms - Optional. A numeric value equal to the milliseconds value to set.
   * @returns The modified DateBuilder instance.
   */
  withHours(hours: number, min?: number, sec?: number, ms?: number): this {
    super.setHours(hours);
    if (min !== undefined) {
      super.setMinutes(min);
    }
    if (sec !== undefined) {
      super.setSeconds(sec);
    }
    if (ms !== undefined) {
      super.setMilliseconds(ms);
    }
    return this;
  }

  /**
   * Sets the minute value in the Date object using local time and returns the modified DateBuilder instance for chaining.
   *
   * @param min - A numeric value equal to the minute value to set.
   * @param sec - Optional. A numeric value equal to the seconds value to set.
   * @param ms - Optional. A numeric value equal to the milliseconds value to set.
   * @returns The modified DateBuilder instance.
   */
  withMinutes(min: number, sec?: number, ms?: number): this {
    super.setMinutes(min);
    if (sec !== undefined) {
      super.setSeconds(sec);
    }
    if (ms !== undefined) {
      super.setMilliseconds(ms);
    }
    return this;
  }

  /**
   * Sets the seconds value in the Date object using local time and returns the modified DateBuilder instance for chaining.
   *
   * @param sec - A numeric value equal to the seconds value to set.
   * @param ms - Optional. A numeric value equal to the milliseconds value to set.
   * @returns The modified DateBuilder instance.
   */
  withSeconds(sec: number, ms?: number): this {
    super.setSeconds(sec);
    if (ms !== undefined) {
      super.setMilliseconds(ms);
    }
    return this;
  }

  /**
   * Sets the milliseconds value in the Date object and returns the modified DateBuilder instance for chaining.
   *
   * @param ms - A numeric value equal to the milliseconds value to set.
   * @returns The modified DateBuilder instance.
   */
  withMilliseconds(ms: number): this {
    super.setMilliseconds(ms);
    return this;
  }

  /**
   * Sets the time of the current DateBuilder instance to midnight (00:00:00.000)
   * in UTC and returns the modified instance for chaining.
   *
   * @returns The modified DateBuilder instance.
   */
  toMidnightUTC(): this {
    const utcYear = super.getUTCFullYear();
    const utcMonth = super.getUTCMonth();
    const utcDate = super.getUTCDate();
    const utcMidnight = new Date(Date.UTC(utcYear, utcMonth, utcDate));
    super.setTime(utcMidnight.getTime());
    return this;
  }

  /**
   * Sets the time of the current DateBuilder instance to midnight (00:00:00.000)
   * local time and returns the modified instance for chaining.
   *
   * @returns The modified DateBuilder instance.
   */
  toMidnightLocal(): this {
    super.setHours(0, 0, 0, 0);
    return this;
  };

  /**
   * Adjusts the date by adding or subtracting the specified number of days and returns the modified DateBuilder instance for chaining.
   *
   * @param days - The number of days to adjust the current date by. 
   * Positive values move the date forward, and negative values move it backward.
   * @returns The modified DateBuilder instance.
   *
   * @example
   * const builder = new DateBuilder()
   *   .adjustDays(5)  // Moves the date 5 days forward
   *   .adjustDays(-2); // Moves the date 2 days backward
   */
  adjustDays(days: number): this {
    super.setDate(super.getDate() + days);
    return this;
  }

  // Add more builder-style methods if needed
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BlockerFunction, useBlocker } from "react-router-dom";

/**
 * This custom hook blocks window navigation and refreshes by using beforeUnload. It also blocks react router navigation by using useBlocker.
 * @param shouldPrevent If true, prevents all navigation. If false, does nothing.
 */
export default function usePreventNavigation(shouldPrevent: boolean) {
  const { t } = useTranslation();
  
  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      shouldPrevent && currentLocation.pathname !== nextLocation.pathname,
    [shouldPrevent]
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldPrevent) {
        event.preventDefault(); // Standard for modern browsers
        event.returnValue = true; // Included for legacy support
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldPrevent]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const confirmLeave = window.confirm(t('general.unsavedChanges'));  
      if (confirmLeave) {
        blocker.proceed();
      }
    }
  }, [blocker])
}

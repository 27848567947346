import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { FormikHelpers } from 'formik';
import { ROLES } from 'utils/utils'
import RoutePathNames from 'routes/routePathNames'
import { useAuthContext } from 'context/auth-context'
import RegistrationForm, { initValues, validSchema } from 'components/common/forms/RegistrationForm'
import * as Yup from 'yup'

const validationSchema = validSchema.shape({
  company_name: Yup.string()
    .min(2, 'Common.CharsMinimum2')
    .required('Registration.EnterCompanyName'),
  name: Yup.string().required('Registration.EnterUsername').min(5, 'Registration.ShortName'),
})

const initialValues = {
  ...initValues,
  company_name: '',
  name: '',
}

type FormValues = typeof initialValues

export default function RegistrationCompany() {
  const { state } = useLocation();
  const previousPath: string = state && state.pathname;
  const navigate = useNavigate()

  const { setRole, registrationCompany } = useAuthContext()

  useEffect(() => {
    setRole(ROLES.COMPANY)
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (
    values: FormValues /* , { setSubmitting }: FormikHelpers<FormValues> */
  ) => {
    const { confirmPassword, agreement, ...restValues } = values

    try {
      await registrationCompany({ ...restValues }, () => {
        navigate(RoutePathNames.company.setupProfile, { replace: true, state: { pathname: previousPath } })
      })
    } catch (error: any) { }
  }

  return (
    <RegistrationForm<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  )
}

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { StyledScrollBox } from 'styles/component.styles';
import { ApplicantStatus, Applicant } from './ApplicationHandler';
import { useTranslation } from 'react-i18next';
import { getMatchColor, getMatchLabel } from 'utils/utils';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  applicantsToEmail: ApplicantStatus;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onSave,
  applicantsToEmail
}) => {
  const { t } = useTranslation();
  // Helper function to render a list of consultants for each category
  const renderConsultantsList = (title: string, applicants: Applicant[]) => (
    <>
      {applicants.length > 0 && (
        <>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <List>
            {applicants.map((applicant) => (
              <ListItem key={applicant.user_id}>
                <ListItemAvatar>
                  <Avatar src={applicant.profile_photo} alt={applicant.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={applicant.name}
                  secondary={
                    <Typography variant="body2" color={getMatchColor(applicant.match)} noWrap>
                      {t('landing.match', { matchLabel: t(getMatchLabel(applicant.match)) })}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('jobListings.confirmChanges')}</DialogTitle>
      <StyledScrollBox>
        <DialogContent dividers>
          {renderConsultantsList(t('jobListings.contractEmail'), applicantsToEmail.contract)}
          {renderConsultantsList(t('jobListings.interviewEmail'), applicantsToEmail.interview)}
          {renderConsultantsList(t('jobListings.reviewEmail'), applicantsToEmail.review)}
          {renderConsultantsList(t('jobListings.rejectEmail'), applicantsToEmail.reject)}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {t('general.cancel')}
          </Button>
          <Button onClick={onSave} color="secondary" variant="contained">
            {t('jobListings.saveAndSend')}
          </Button>
        </DialogActions>
      </StyledScrollBox>
    </Dialog>
  );
};

export default ConfirmationDialog;

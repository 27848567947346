import { useMutation } from 'react-query'
import { api } from '../api'
import { CvSearchParams, CvSearchResult } from '../cvs/mutations'
import { pageLimitCount } from '../../utils/utils'
import { UpdateUser } from '../user/queries'
import { Manager, ManagerApplyForJob, managerKeys } from './queries'
import { CreateCvDto, UserCvsBody } from 'api/cvs/queries'

export type UpdateManager = Partial<Manager>

export type UploadConsultantCv = {
  email: string
  file: File
}

export type UpdateConsultantByManagerBody = {
  consultantId: string | number
  body: UpdateUser
}

export type CreateConsultantCvByManagerBody = {
  body: UserCvsBody
}

const updateManager = async (body: UpdateManager) => {
  const response = await api.patch('manager', body)
  return response.data
}

export const updateConsultantByManager = async ({
  consultantId,
  body,
}: UpdateConsultantByManagerBody): Promise<void> => {
  if (consultantId && body) {
    const response = await api.patch(`manager/update/consultant/${consultantId}`, body)
    return response.data
  }
  return Promise.resolve(undefined)
}

export const createConsultantCvByManager = async (
  body: CreateCvDto
): Promise<void> => {
  if (body) {
    const response = await api.post(`manager/create/consultant`, body)
    return response.data
  }
  return Promise.resolve(undefined)
}

const searchConsultantByManager = async ({
  body,
  offset = 0,
  limit = pageLimitCount,
  onlyManagerConsultants = true
}: {
  body: CvSearchParams
  offset?: number
  limit?: number
  onlyManagerConsultants?: boolean
}): Promise<CvSearchResult> => {
  if (onlyManagerConsultants) {
    const response = await api.post(`manager/search?offset=${offset}&limit=${limit}`, body)
    return response.data
  } else {
    const response = await api.post(`manager/search-all?offset=${offset}&limit=${limit}`, body)
    return response.data
  }
}

export const applyForJob = async (body: ManagerApplyForJob) => {
  const response = await api.post('manager/apply-for-a-job', body)
  return response.data
}

export const deleteConsultantProfile = async ({ user_id }: { user_id?: string | number }) => {
  if (user_id) {
    const response = await api.delete(`manager/consultant/${user_id}`)
    return response.data
  }
  return Promise.resolve(undefined)
}

export const useUpdateManagerMutation = () => useMutation(managerKeys.manager, updateManager)
export const useUpdateConsultantByManagerMutation = (consultantId = '') =>
  useMutation(managerKeys.updateConsultantByManager(consultantId), updateConsultantByManager)
export const useCreateConsultantCvByManagerMutation = () =>
  useMutation(managerKeys.createConsultantCvByManager(), createConsultantCvByManager)
export const useSearchConsultantByManagerMutation = () =>
  useMutation(managerKeys.search(), searchConsultantByManager)
export const useManagerApplyForJobMutation = () => useMutation(managerKeys.applyForJob(), applyForJob)
export const useManagerDeleteConsultantProfileMutation = () =>
  useMutation(managerKeys.deleteConsultant(), deleteConsultantProfile)

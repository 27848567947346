import { api } from 'api/api';
import { useMutation } from 'react-query';

export const notificationKeys = {
  notification: () => ['notification'] as const,
  updateNotification: () => [...notificationKeys.notification(), 'update'] as const,
}

const updateNotification = async ({ notification_id, read }: { notification_id: number, read: boolean }) => {
  const response = await api.patch(`/notification/${notification_id}/read`, { read });
  return response.data;
};

export const useUpdateNotificationMutation = () => useMutation(notificationKeys.updateNotification(), updateNotification);

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { format, formatDistanceToNow } from 'date-fns'
import { Box, Typography, Button, Avatar, Paper, Stack, ButtonBase } from '@mui/material'
import { getWorkloadStatus, getMatchColorStatus, getMatchLabel } from 'utils/utils'
import RoutePathNames from 'routes/routePathNames'
import { Assignments } from 'api/assignments/queries'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BiTimeFive } from 'react-icons/bi'
import Chip from '../ui/Chip'
import { useAuthContext } from 'context/auth-context'
import { AssignmentSearchResult } from 'api/assignments/mutations'

type AssignmentCardProps = {
  card: AssignmentSearchResult['data'][0] | Assignments[0]
}

export default function AssignmentCard({ card }: AssignmentCardProps) {
  const { t } = useTranslation()
  const { isUserRoleConsultant } = useAuthContext();
  const [isHovered, setIsHovered] = useState(false);

  const cardData = card.body;
  const consultant_match = 'consultant_match' in card ? card.consultant_match : undefined;
  const search_match = 'search_match' in card ? card.search_match : undefined;
  const id = card.assignment_id;

  const { jobTitle, availableFrom, workLoad, name, createdAt, duration, location, profile_photo } =
    cardData || {}

  return (
    <ButtonBase
      sx={(theme) => ({
        maxWidth: { xs: '100%', sm: 276 },
        width: '100%',
        borderRadius: theme.shape.rounded
      })}
    >
      <Paper
        id={`assignment-${id}`}
        elevation={isHovered ? 8 : 2}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        component={Link}
        to={`${RoutePathNames.consultant.jobListings}/${id}`}
        sx={(theme) => ({
          padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
          borderRadius: theme.shape.rounded,
          bgcolor: 'common.white',
          width: '100%'
        })}
      >
        <Stack spacing={1.5}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
              textAlign: 'left'
            }}
            variant="h5"
          >
            {jobTitle}
          </Typography>

          {(consultant_match !== undefined || search_match !== undefined) && (
            <Stack direction='row' justifyContent="space-between" sx={{ mx: -0.5 }}>
              {search_match !== undefined && consultant_match === undefined && (
                <Chip
                  label={t('landing.searchMatch', { matchLabel: t(getMatchLabel(search_match)) })}
                  variant="custom"
                  color={getMatchColorStatus(search_match)}
                />
              )}

              {consultant_match !== undefined && search_match === undefined && (
                <Chip
                  label={t(
                    isUserRoleConsultant ?
                      'landing.profileMatch' :
                      'landing.consultantMatch',
                    { matchLabel: t(getMatchLabel(consultant_match)) }
                  )}
                  variant="custom"
                  color={getMatchColorStatus(consultant_match)}
                />
              )}

              {consultant_match !== undefined && search_match !== undefined && (
                <>
                  <Chip
                    label={t('landing.searchMatch', { matchLabel: t(getMatchLabel(search_match)) })}
                    variant="custom"
                    color={getMatchColorStatus(search_match)}
                  />
                  <Chip
                    label={t(
                      isUserRoleConsultant ?
                        'landing.profileMatch' :
                        'landing.consultantMatch',
                      { matchLabel: t(getMatchLabel(consultant_match)) }
                    )}
                    variant="custom"
                    color={getMatchColorStatus(consultant_match)}
                  />
                </>
              )}
            </Stack>
          )}

          <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex" alignItems="center" gap={0.5} color="secondary.main">
              <HiOutlineLocationMarker className="text-violet-blue" size={16} />
              <Typography variant="body-sm" color="primary.60">
                {location?.city}
              </Typography>
            </Box>
            {workLoad && (
              <Box display="flex" alignItems="center" gap={1} color="secondary.main">
                <BiTimeFive className="text-violet-blue" size={16} />
                <Typography variant="body-sm" color="primary.60">
                  {t(getWorkloadStatus(workLoad))}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="body-sm" fontWeight={400} color="primary.80">
                {t('landing.start')}
              </Typography>
              <Typography variant="body-sm" color="primary.60">
                {format(availableFrom ? new Date(availableFrom) : new Date(), 'yyyy-MM-dd')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="body-sm" fontWeight={400} color="primary.80">
                {t('landing.duration')}
              </Typography>
              <Typography variant="body-sm" color="primary.60">
                {t('general.durationMonths', { count: duration })}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexWrap="nowrap" alignItems="center" gap={1}>
            <Avatar
              src={profile_photo ?? ''}
              alt="Company logo"
              variant="square"
              sx={(theme) => ({ width: '28px', height: '28px', borderRadius: theme.shape.rounded })}
            />
            <Box
              display="flex"
              alignItems="center"
              flexGrow={1}
              gap={0.5}
              fontSize={10}
              color="text.secondary"
              lineHeight="110%"
            >
              <Box display="flex" flexDirection={'column'} gap={0.5}>
                <Box
                  component="strong"
                  color="common.black"
                  flexShrink={1}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '130px',
                  }}
                >
                  {name?.raw},
                </Box>{' '}
                {createdAt ? (
                  <span style={{ flexShrink: '0' }}>
                    {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
                  </span>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Stack>
      </Paper>
    </ButtonBase>
  )
}
